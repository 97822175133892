import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CourseCardMini from "./CourseCardMini";
import CourseViewsEmpty from "./empty/CourseViewsEmpty";
import CourseViewsLoading from "./loading/CourseViewsLoading";

const CourseViews = (props) => {
    const [courseViews, setCourseViews] = useState([]);
    useEffect(() => {
        setCourseViews(props?.data);
    }, [props?.data]);

    return (
        <>
            {
                courseViews ?
                    <>
                        <Grid container>
                            <Typography
                                align="center"
                                style={{
                                    fontSize: "24px",
                                    color: "#ad2831",
                                    fontWeight: "bold",
                                    paddingTop: "10px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    paddingBottom: "5px",
                                }}
                            >
                                Viewed Courses
                            </Typography>
                        </Grid>
                        <Grid container>
                            {
                                courseViews.length > 0 ?
                                    courseViews?.map((course, i) => {
                                        return (
                                            <CourseCardMini courseSlug={course} key={i} />
                                        );
                                    })
                                    : <CourseViewsEmpty />
                            }
                        </Grid>
                    </>
                    : <CourseViewsLoading />
            }
        </>
    );
}

export default CourseViews;