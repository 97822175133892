import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CourseCardAttempt from "./CourseCardAttempt";
import CourseTakenEmpty from "./empty/CourseTakenEmpty";
import CourseTakenLoading from "./loading/CourseTakenLoading";

const CourseTaken = (props) => {
    const [courseTaken, setCourseTaken] = useState([]);
    useEffect(() => {
        setCourseTaken(props?.data);
    }, [props?.data]);

    return (
        <>
            {
                courseTaken ?
                    <>
                        <Grid container>
                            <Typography
                                align="center"
                                style={{
                                    fontSize: "24px",
                                    color: "#ad2831",
                                    fontWeight: "bold",
                                    paddingTop: "10px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    paddingBottom: "5px",
                                }}
                            >
                                Course Taken
                            </Typography>
                        </Grid>
                        <Grid container>
                            {
                                courseTaken.length > 0 ?
                                    courseTaken?.map((course, i) => {
                                        return (
                                            <CourseCardAttempt course={course} key={i} />
                                        );
                                    })

                                    : <CourseTakenEmpty />
                            }
                        </Grid>
                    </>
                    : <CourseTakenLoading />
            }
        </>
    );
}

export default CourseTaken;