import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import CourseViews from "./custom/CourseViews";
import CourseTaken from "./custom/CourseTaken";
import UserInfo from "./custom/UserInfo";
import UserDoubts from "./custom/UserDoubts";
import UserTasks from "./custom/UserTasks";

import { Grid, CircularProgress } from "@material-ui/core";
import Certificate from "./custom/Certificate";


const UserDetails = (props) => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [userTaskData, setUserTaskData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getUser();
    }, []);


    const getUser = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/users/${props?.userID}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const selectedUserData = (await response.json());
                console.log(selectedUserData);
                if (selectedUserData.apiStatus != "Success" && selectedUserData.apiStatus != "SUCCESS" && selectedUserData.apiStatus != "success") {
                    history.push("/admin/users");
                }
                setUserData(selectedUserData?.data);
                setUserTaskData(selectedUserData?.data?.coursePlaylist?.filter(x => x.type == "task"));
                setLoading(false);
            } catch (error) {
                console.log("Courses cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }


    return (
        <>
            {loading ?
                <div className="w-screen h-screen grid place-items-center">
                    <CircularProgress />
                </div>
                :
                <>
                    <UserInfo name={userData?.name} email={userData?.email} profile={userData?.profilePhoto} uid={userData?.uid} />
                    {
                        userData?.courseViews?.length > 0 ?
                            <Grid container>
                                <CourseViews courses={userData?.courseViews} />
                            </Grid>
                            :
                            null
                    }
                    {
                        userData?.courseTaken?.length > 0 ?
                            <Grid container>
                                <CourseTaken courses={userData?.courseTaken} />
                            </Grid>
                            :
                            null
                    }
                    {
                        userData?.courseTaken?.length > 0 ?
                            <Grid container>
                                <Certificate courses={userData?.courseTaken} />
                            </Grid>
                            :
                            null
                    }
                    {
                        userData?.userDoubts?.length > 0 ?
                            <Grid container>
                                <UserDoubts doubts={userData?.userDoubts} />
                            </Grid>
                            :
                            null
                    }
                    {
                        userTaskData.length > 0 ?
                            <>
                                <Grid container>
                                    <UserTasks tasks={userTaskData} />
                                </Grid>
                            </>
                            :
                            null
                    }

                </>
            }
        </>
    );
}

export default UserDetails;