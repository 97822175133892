import React from "react";

const Tutors = () => {
  return (
    <div className="flex items-start justify-center py-20">
      {/* left section with tutors */}
      <div className="relative flex flex-col-reverse lg:flex-row justify-between w-screen lg:w-4/5">
        {/* tutor section  */}
        <section className="w-screen px-2 lg:px-0 md:px-0 lg:w-4/5">
          <div className="w-full py-16 md:py-20 h-[200vh]  md:h-[190vh] lg:h-[185vh] overflow-y-scroll grid grid-cols-1 gap-10">
            <div className="h-full relative w-full">
              <h3 className="absolute top-[-4rem] left-1/2  md:left-8 transform -translate-x-1/2 md:-translate-x-0">Course Mentor</h3>

              <div
                className="absolute z-20 left-1/2  md:left-[25vw] transform -translate-x-1/2 md:-translate-x-0 lg:left-0 rounded-full overflow-hidden w-80 h-80 md:w-96 md:h-96 cursor-pointer flex flex-col items-center justify-end group"
                style={{
                  boxShadow: "0 -11px 15px #f99c3850",
                }}
              >
                <div
                  className="absolute z-40 w-80 h-80 md:w-96 md:h-96"
                  style={{
                    background:
                      "radial-gradient(circle at bottom right, transparent 60%, #f99c38 90%)",
                  }}
                ></div>

                <div className="text-center absolute top-10 text-white z-50">
                  <h3 className="text-xl font-bold">Ashfaq Ahmed</h3>
                  <p className="translate text-3xl font-extralight">
                    Founder
                  </p>
                </div>
                <div className="relative w-60 h-60 md:w-72 md:h-72 overflow-hidden rounded-full">
                  <img
                    src="./genai/mentor-0.png"
                    alt="Tutor 1"
                    className="w-full h-full absolute object-cover transition-transform duration-300 transform "
                  />
                </div>
              </div>
              <div
                className="absolute left-[70vw] md:left-[70vw] lg:left-[33vw] top-80 md:top-24 z-20 rounded-full overflow-hidden w-28 h-28 cursor-pointer shadow-md flex flex-col items-center justify-center"
                style={{
                  boxShadow: "0 10px 20px rgba(249, 156, 56, 0.8)",
                  background:
                    "radial-gradient(circle at bottom right, transparent 30%, #f99c38 75%)",
                }}
              >
                <p className="font-semibold text-sm">RecruitingMonk</p>
              </div>

              <h3 className="absolute top-[26rem] md:top-[26rem] left-1/2  md:left-8 transform -translate-x-1/2 md:-translate-x-0">
                Guest Mentors
              </h3>

              <div
                className="absolute z-50 top-[30rem] bg-white left-1/2 transform -translate-x-1/2 md:-translate-x-0 md:left-[35vw] lg:left-52 rounded-full overflow-hidden w-80 h-80 md:w-96 md:h-96 cursor-pointer shadow-lg flex flex-col items-center justify-end group"
                style={{
                  boxShadow: "0 -11px 15px #f99c3850",
                }}
              >
                <div
                  className="absolute z-40 w-80 h-80 md:w-96 md:h-96"
                  style={{
                    background:
                      "radial-gradient(circle at bottom right, transparent 65%, #f99c38 95%)",
                  }}
                ></div>
                <div className="text-center absolute top-10 text-white z-10">
                  <h3 className="text-xl font-bold">Victor C.</h3>
                  <p className="translate text-3xl font-extralight">Founder</p>
                </div>
                <div className="relative w-56 h-56 md:w-64 md:h-64 overflow-hidden rounded-full">
                  <img
                    src="/genai/mentor-2.png"
                    alt="Tutor 1"
                    className="w-full h-full object-cover transition-transform duration-300 transform "
                  />
                </div>
              </div>

              <div
                className="absolute left-[10vw] md:left-[20vw] lg:left-12 bottom-96  md:top-[32rem] z-20 rounded-full overflow-hidden w-28 h-28 cursor-pointer shadow-md flex flex-col items-center justify-center"
                style={{
                  boxShadow: "0 10px 20px rgba(249, 156, 56, 0.8)",
                  background:
                    "radial-gradient(circle at bottom right, transparent 25%, #f99c38 65%)",
                }}
              >
                <p className="font-semibold text-sm">mypathfinder</p>
              </div>

              <div
                className="absolute z-40 rounded-full bottom-12 lg:bottom-[-40px] 2xl:bottom-32 left-1/2 transform -translate-x-1/2 md:-translate-x-0 md:left-[22vw] lg:left-4 overflow-hidden w-80 h-80 md:w-96 md:h-96 cursor-pointer flex flex-col items-center justify-end group"
                style={{
                  boxShadow: "0 -11px 15px #f99c3850",
                }}
              >
                <div
                  className="absolute z-50 w-80 h-80 md:w-96 md:h-96"
                  style={{
                    background:
                      "radial-gradient(circle at bottom right, transparent 55%, #f99c38 120%)",
                  }}
                ></div>
                <div className="text-center absolute top-10 text-white z-10">
                  <h3 className="text-xl font-bold">Colin Panicker</h3>
                  <p className="translate text-3xl font-extralight">
                    Chief of Staff
                  </p>
                </div>
                <div className="relative w-56 h-56 md:w-64 md:h-64 overflow-hidden rounded-full">
                  <img
                    src="./genai/mentor-1.png"
                    alt="Tutor 1"
                    className="w-full h-full object-cover transition-transform duration-300 transform "
                  />
                </div>
              </div>

              <div
                className="absolute left-[66vw] lg:left-[30vw] bottom-[-30px] text-center md:bottom-0 lg:bottom-30 z-20 rounded-full overflow-hidden w-36 h-36 cursor-pointer shadow-md flex flex-col items-center justify-center"
                style={{
                  boxShadow: "0 10px 20px rgba(249, 156, 56, 0.8)",
                  background:
                    "radial-gradient(circle at bottom right, transparent 20%, #f99c38 60%)",
                }}
              >
                <p className="font-semibold text-sm">
                  intellect <br />
                  Design Arena <br /> Ltd
                </p>
              </div>
            </div>
          </div>
          <div className="w-full mt-20 flex flex-col gap-14">
            <div>
              <h3>Is the mentor qualified to teach this Subject?</h3>
              <p className="text-lg mt-6">
                Ashfaq has spent{" "}
                <span className="font-semibold" style={{ color: "#f99c38" }}>
                  10+ years of experience
                </span>
                , training and nurturing Recruiters. His innovative BigData
                concepts in Sourcing is derived from Data Analysis concepts. In
                last 2 years, he is been busy building HRTech solutions &
                working with complex API solutions via
                <span className="ml-2 bg-red-600 text-white px-2 py-1 rounded">
                  Engineering Prompts
                </span>
              </p>
            </div>
            <div>
              <h3>Some of his notable solutions using GAI</h3>
              <p className="mt-6 text-lg">
                Built a Prompt to classify Product vs Service company with an
                efficacy of 81% Engineered Prompts to spot BIASes in Sourcing
                Via his HRTech app TAbuddy, his GAI APIs process 100,000 tokens
                per day
              </p>
            </div>
          </div>
        </section>

        <section className="lg:sticky lg:top-1/3 lg:w-2/5 text-center lg:h-80 p-6 flex flex-col items-center justify-start">
          <h2 className="text-4xl mb-1 font-bold">
            Master GEN AI in <br /> 3 months
          </h2>

          <button
            className="w-full px-4 py-2 text-white rounded cursor-pointer font-medium mt-8"
            style={{ backgroundColor: "#f99c38", border: "2px solid #f99c38" }}
            onClick={() => window.open("https://wa.me/+917416266999", "_blank")}
         >
            Join Now
          </button>

          <p style={{ paddingTop: "5px" }}>Course Starts on Jan 26th 2025</p>
        </section>
      </div>
    </div>
  );
};

export default Tutors;
