import React, { useEffect, useState } from "react";
import styles from "./StudentTestimonials.module.css";
import ReactPlayer from "react-player";

const StudentTestimonials = ({
  selectedtestimonailOption,
  setSelectedtestimonailOption,
}) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const cardData = [
    {
      imageUrl: "/images/testimonials/testimonial1a.png",
      content: `“I consider myself fortunate to be able to learn from you and your
              experiences. Your sessions have helped me to make it beyond what I
              imagined myself capable of achieving. I cant thank you enough.”`,
      name: "KRITIKA",
      designation: "Technical Recruiter",
      company: "@Google",
    },

    {
      imageUrl:
        "/images/testimonials/testimonial5.jpeg",
      content: `“This course is useful to recruiters who wants to understand sourcing from Data Analysis, Just started to implement & can see change in my productivity. My takeaways from this Sourcing Master Class were, 'Efficacy Models'. Overall the training structure was excellent.”`,
      name: "AMULYA GOPAL ",
      designation: "Talent Partner ",
      company: "@CRED",
    },

    {
      imageUrl: "/images/testimonials/testimonial3.jpg",
      content: `“I'm thankful to both Ashfaq Ahmed & Raghunandan Patre. It was
              great learning & enriching of skills. Of course one could see huge
              transformation if they apply the basic principles coached by these
              two Monks. Last but not the least, want to thank Yaswanth Lohar
              Veeravalli ✌”`,
      name: "ARSHAD",
      designation: "Principal Recruiter",
      company: "@24*7.ai",
    },

    {
      imageUrl: "/images/testimonials/testimonial4.jpg",
      content: `“It was incredible Ashfaq! I must thank you that its post your
              courses I was able to make the cut & get my first job in Canada,
              which is not so easy as they always look for Canadian experience.
              Your hack & techniques are super helpful.”`,
      name: "SNEHA",
      designation: "Seniour Sourcer",
      company: "@Thomson Reuters",
    },

    {
      imageUrl:
        "/images/testimonials/testimonial6.jpeg",
      content: `“NOT operator is the first thing which really negate most of your duplicate data and NOT really helps to negate that and I've really learnt that here and I've also learnt to build the efficacy structure how do you break down your entire data once you get it and how do you break entire your jd.”`,
      name: "Vivian Kristoper",
      designation: "Technical Recruiter",
      company: "@Microsoft",
    },
  ];

  const videoData = [
    {
      content: `“The material & content what we learnt here, we'll not find anywhere else.”`,
      video:
        "https://player.vimeo.com/video/1005952835?h=1ceddf15b2&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      name: "Caroline G.",
      designation: "Tech Recruiter",
      company: "@Toughbyte",
    },

    {
      content: `"I'm thankful to both Ashfaq Ahmed & Raghunandan Patre.”`,
      video: "https://vimeo.com/857044983/7e6f920ac8",
      name: "KOMAL CHOUDHARI",
      designation: "TA Manager",
      company: "@Energy Exemplar",
    },

    {
      content: `“It was a nice refresher but also went above and beyond that!”`,
      video: "https://vimeo.com/857045125/2d1e0fcdc0",
      name: "ANIMESH PRASAD",
      designation: "TA Lead",
      company: "@Annalect",
    },
  ];

  const leadersData = [
    {
      video: false,
      imageUrl:
        "/images/testimonials/testimonial7.jpeg",
      content: `“As I refer my notes, I cannot believe the range of best practices that they alluded to. I already have doubts if I can navigate -with the ease Ashfaq the magician -did!”`,
      name: "Achyut Menon",
      designation: "Director Talent Acquisition",
      company: "@Options Executive Search Pvt Ltd.",
    },
    {
      video:
        "https://player.vimeo.com/video/1005936118?h=07f5044156&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      name: "Priya Adharsh ",
      designation: "Hiring Lead ",
      company: "@Pocket FM",
    },

    {
      video:
        "https://player.vimeo.com/video/1005937102?h=0f20c8a598&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      name: "Sundeep Dasa",
      designation: "Associate Director - HR",
      company: "@ValGenesis",
    },
    {
      video:
        "https://player.vimeo.com/video/1005936914?h=8974847b7f&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      name: "Abhishek Goyal",
      designation: "Manager, Talent Acquisition",
      company: "@AGDATA, LP",
    },

    {
      video: "https://vimeo.com/857044868/b4c790daf8",
      name: "Shika Abrol",
      designation: "ManagerManager",
      company: "@Credera",
    },
    {
      video: false,
      imageUrl:
        "/images/testimonials/testimonials8.jpeg",
      content: `“Entire Monk team is super amazing & pretty supportive. For someone who comes from a non-tech background, they ensured that I not just understood tech, but gave me confidence + right techniques to engage with top techies. What I’ve learnt here will come with me life long.”`,
      name: "Piyush N",
      designation: "Associate Director - Talent Insights",
      company: "@Annalect India",
    },
  ];

  return (
    <div className={styles.testimonial_outer_container}>
      <div
        className={styles.testimonial_container}
        style={{
          backgroundImage: `url(/images/testimonialbg.png)`,
          height: `${
            screenSize < 600
              ? selectedtestimonailOption === "recruiter"
                ? "3400px"
                : "2600px"
              : screenSize < 900 && selectedtestimonailOption !== "recruiter"
              ? "80vh"
              : ""
          }`,
        }}
      >
        <div className={styles.testimonail_heading_section}>
          <div className={styles.testimonail_heading}>
            Our students love us!
          </div>
          <div className={styles.testimonails_options}>
            <div
              className={`${styles.testimonial_option} ${
                selectedtestimonailOption === "recruiter"
                  ? `${styles.selectedtestimonialOption}`
                  : ""
              }`}
              onClick={() => {
                setSelectedtestimonailOption("recruiter");
              }}
              style={{
                borderRight: "1.5px solid #000",
                borderRadius: "30px 0 0 30px",
              }}
            >
              Recruiters
            </div>
            <div
              className={`${styles.testimonial_option} ${
                selectedtestimonailOption === "leader"
                  ? `${styles.selectedtestimonialOption}`
                  : ""
              }`}
              style={{ borderRadius: " 0 30px 30px 0" }}
              onClick={() => {
                setSelectedtestimonailOption("leader");
              }}
            >
              Leaders
            </div>
          </div>
        </div>

        {selectedtestimonailOption === "recruiter" ? (
          <div className={styles.testimonial_card_containers}>
            {cardData.map((data, key) => (
              <div
                className={`${styles["testimonial_card"]} ${
                  styles[`testimonaial_cards_positioning${key}`]
                } ${
                  selectedCard === key ? styles["testimonial_top_card"] : ""
                }`}
                key={key}
                onClick={() => setSelectedCard(key)}
              >
                <div className={styles.testimonial_card_content}>
                  {data.content}
                </div>

                <div className={styles.testimonial_card_info}>
                  <img
                    src={data.imageUrl}
                    alt=""
                    className={styles.testimonial_image}
                  />

                  <div className={styles.testimonial_card_info_text}>
                    <div className={styles.testimonial_card_name}>
                      {data.name}
                    </div>
                    <div className={styles.testimonial_card_position}>
                      {data.designation}
                    </div>
                    <div className={styles.testimonial_card_company}>
                      {data.company}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {videoData.map((data, key) => (
              <div
                className={`${styles["testimonial_video_card"]} ${
                  styles[`testimonaial_cards_positioning${key + 5}`]
                } ${
                  selectedCard === key + 5
                    ? styles["testimonial_top_card_video"]
                    : ""
                }`}
                key={key}
                onClick={() => {
                  setSelectedCard(key + 5);
                }}
              >
                <div className={styles.testimonial_video_card_content}>
                  {data.content}
                </div>

                <div className={styles.testimonial_video_container}>
                  <ReactPlayer
                    url={data.video}
                    // controls
                    width="297.5px"
                    height="200px"
                    controls
                  />
                </div>

                <div className={styles.testimonial_card_info_text_video}>
                  <div className={styles.testimonial_card_name}>
                    {data.name}
                  </div>
                  <div className={styles.testimonial_card_position}>
                    {data.designation}
                  </div>
                  <div className={styles.testimonial_card_company}>
                    {data.company}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.testimonia_card_containers}>
            {leadersData.map((data, key) =>
              data.video ? (
                <div
                  className={`${styles["testimonial_video_card"]} ${
                    styles[`testimonaial_cards_positioning${key + 15}`]
                  } ${
                    selectedCard === 15 + key
                      ? styles["testimonial_top_card-leader"]
                      : ""
                  }`}
                  key={key}
                  onClick={() => {
                    setSelectedCard(15 + key);
                  }}
                >
                  <div className={styles.testimonial_video_container}>
                    <ReactPlayer
                      url={data.video}
                      // controls
                      width="297.5px"
                      height="200px"
                      controls
                    />
                  </div>

                  <div className={styles.testimonial_card_info_leader}>
                    <div className={styles.testimonial_card_info_text}>
                      <div className={styles.testimonial_card_name}>
                        {data.name}
                      </div>
                      <div className={styles.testimonial_card_position}>
                        {data.designation}
                      </div>
                      <div className={styles.testimonial_card_company}>
                        {data.company}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles["testimonial_card"]} ${
                    styles[`testimonaial_cards_positioning${15 + key}`]
                  } ${
                    selectedCard === 15 + key ? styles["testimonial_top_card"] : ""
                  }`}
                  key={key}
                  onClick={() => setSelectedCard(15 + key)}
                >
                  <div className={styles.testimonial_card_content}>
                    {data.content}
                  </div>

                  <div className={styles.testimonial_card_info}>
                    <img
                      src={data.imageUrl}
                      alt=""
                      className={styles.testimonial_image}
                    />

                    <div className={styles.testimonial_card_info_text}>
                      <div className={styles.testimonial_card_name}>
                        {data.name}
                      </div>
                      <div className={styles.testimonial_card_position}>
                        {data.designation}
                      </div>
                      <div className={styles.testimonial_card_company}>
                        {data.company}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentTestimonials;
