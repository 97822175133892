import React from "react";

const CourseCertificateLoading = () => {
    return (
        <>
            <div className="p-4 md:w-1/4  sm:w-1/2">
                <div className="bg-red-300 animate-pulse h-10 w-1/2 md:w-1/4 my-3"></div>
                <div className="h-full border-2 border-gray-200 rounded-2xl overflow-hidden">
                    <div className="h-80 md:h-96 bg-gray-100 w-full object-cover object-center"></div>
                    <div className="p-3">
                        <p className="leading-relaxed w-full h-3 animate-pulse bg-red-400"></p>
                        <p className="leading-relaxed mb-3 w-1/3 h-3 animate-pulse bg-gray-400"></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CourseCertificateLoading;