import { Grid, Typography } from "@material-ui/core";
import React from "react";

const Title = (props) => {
    return (
        <>
            <Grid container className="pt-5 px-5">
                <div
                    className="text-3xl font-bold"
                    style={{ color: "#ad2831" }}
                >
                    {props?.title}
                </div>
            </Grid>
        </>
    );
}

export default Title;