import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ImageUpload from "./ImageUpload";
import Swal from "sweetalert2";

const CoursePreview = (props) => {
    const { handleNext, handleBack } = props;


    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    onClick={handleNext}
                    className="button-default"
                    variant="contained"
                    color="primary"
                >
                    Next
                </Button>
            </Box>
        </>
    );
}

export default CoursePreview;
