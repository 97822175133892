import React, { useState } from "react";

import { auth } from "../../firebase";

import { Avatar } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const DoubtAnswer = (props) => {
    const ansid = props.ansid;
    const [answer, setAnswer] = useState([]);
    const [answerLoading, setAnswerLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (props.answer !== undefined && props.answer !== null) {
            setAnswer(props.answer);
        } else {
            getAnswer();
        }
    }, [ansid, props]);

    const getAnswer = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            try {
                                setAnswerLoading(true);
                                const response = await fetch(`https://elearning-monks.azurewebsites.net/users/doubts/answer/get`, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken,
                                        ansid: ansid
                                    })
                                });
                                const answerData = (await response.json());
                                if (answerData.apiStatus && answerData.apiStatus != "SUCCESS" && answerData.apiStatus != "Success" && answerData.apiStatus != "success") {
                                    history.push('/courses')
                                }
                                setAnswer(answerData.data);
                                // console.log(answerData.data)
                                setAnswerLoading(false);
                            } catch (error) {
                                console.log(error)
                            }
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }
    }

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const updated = new Date(answer?.createdAt);

    return (
        <>
            <div>
                <div className="replies-div">
                    <Avatar
                        style={{ marginRight: "15px" }}
                        alt={answer?.name}
                        src={
                            answer?.profilePhoto ||
                            "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
                        }
                    />
                    <div>
                        <p style={{ color: "blue", marginBottom: "10px" }}>
                            {answer?.name}
                        </p>
                        <p className="caption">
                            <i>Added {updated?.toLocaleDateString("en-us", options)}</i>
                        </p>
                    </div>
                </div>
                <div>
                    <p id="ans">
                        {answer?.answer}
                    </p>
                </div>
                <Divider />
            </div>
        </>
    );
}

export default DoubtAnswer;

