import React from "react";

const Playlist = () => {
    return (
        <>
            <div
                className="flex flex-col w-full my-4 p-2"
                style={{ height: "400px" }}
            >
                <div className="flex flex-col w-full m-0">
                    <div className="flex flex-row h-16 w-full items-center bg-white my-1 rounded-sm shadow-xl p-4 justify-between">
                    </div>
                    <div className="flex flex-row h-16 w-full items-center bg-white my-1 rounded-sm shadow-xl p-4 justify-between">
                    </div>
                    <div className="flex flex-row h-16 w-full items-center bg-white my-1 rounded-sm shadow-xl p-4 justify-between">
                    </div>
                    <div className="flex flex-row h-16 w-full items-center bg-white my-1 rounded-sm shadow-xl p-4 justify-between">
                    </div>
                    <div className="flex flex-row h-16 w-full items-center bg-white my-1 rounded-sm shadow-xl p-4 justify-between">
                    </div>
                    <div className="flex flex-row h-16 w-full items-center bg-white my-1 rounded-sm shadow-xl p-4 justify-between">
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default Playlist;


