// Global imports
import React, { useEffect } from "react";

// Design Imports
import { Grid } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../assets/home/mainlandingpage.css";
import { firebaseAnalytics } from "../../firebase";

// Components Imports
import Header from "../../component/global/Header";
import BannerSlider from "../../component/home/BannerSlider";
import Featured from "../../component/home/Featured";
import Author from "../../component/courses/Author";
import Testimonials from "../../component/home/Testimonials";
import Footer from "../../component/global/Footer";

const Home = () => {
    useEffect(() => {
        firebaseAnalytics.logEvent("homepage_visited");
        document.title = "Elearning - Recruiting Monk";
    });

    return (
        <>

            <Grid container>
                <Header />
                <BannerSlider />
                <Featured />
                <Author />
                <Testimonials />
                <Footer />
            </Grid>
        </>
    );
}

export default Home;