import React from 'react'
import Header from '../../component/gen_course/Header'
import Tutors from '../../component/gen_course/Tutors'
import AboutCourse from '../../component/gen_course/AboutCourse'
import FAQ from '../../component/gen_course/FAQ'
import CTA from '../../component/gen_course/CTA'


const GenCourse = () => {
  return (
    <div>
        <Header />
        <Tutors  />
        <AboutCourse />
        < FAQ/>
        <CTA />
    </div>
  )
}

export default GenCourse