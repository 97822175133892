import React from "react";

const Synopsis = () => {
    return (
        <>
            <div>
                <div className="h-8 md:w-1/2 w-full animate-pulse bg-red-300 mb-12"></div>
                <div className="w-1/2 h-7 animate-pulse bg-gray-700 mb-2"></div>
                <div className="w-full h-4 animate-pulse bg-gray-300 mb-2"></div>
                <div className="w-full h-4 animate-pulse bg-gray-300 mb-2"></div>
                <div className="w-full h-4 animate-pulse bg-gray-300 mb-2"></div>
                <div className="w-full h-4 animate-pulse bg-gray-300 mb-8"></div>
            </div>
        </>
    );
}

export default Synopsis;


