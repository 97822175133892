import React from "react";

const CourseTakenLoading = () => {
    return (
        <>
            <div className="p-2">
                <div className="bg-red-300 animate-pulse h-10 w-1/2 md:w-1/4 mb-3"></div>
            </div>
            <div className="p-4 md:w-1/4  sm:w-1/2">
                <div className="h-full border-2 border-gray-200 rounded-2xl overflow-hidden">
                    <div className="h-36 md:h-24 bg-gray-400 w-full object-cover object-center"></div>
                    <div className="p-6">
                        <div className="flex justify-between mb-4">
                            <div>
                                <h1 className="w-36 mb-4 h-4 animate-pulse bg-red-400"> &nbsp; </h1>
                                <h2 className="bg-gray-800 animate-pulse h-4 w-36 mb-2"> &nbsp; </h2>
                            </div>
                        </div>
                        <p className="leading-relaxed mb-3 w-1/3 h-3 animate-pulse bg-gray-400"></p>
                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-red-400"></p>
                        <p className="leading-relaxed mb-3 w-1/3 h-3 animate-pulse bg-gray-400"></p>
                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-red-400"></p>
                        <p className="leading-relaxed mb-3 w-1/3 h-3 animate-pulse bg-gray-400"></p>
                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-red-400"></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CourseTakenLoading;