import React,{ useEffect } from "react";
import "./appinfo.css";
import Header from "../../component/global/Header";
import Footer from "../../component/global/Footer";
import { firebaseAnalytics } from "../../firebase";

const Privacy = () => {
    useEffect(()=>{
        document.title = "Privacy Policy | Elearning - Recruiting Monk";
        firebaseAnalytics.logEvent("privacy_policy_visited");
    });
    
    return (
        <>
        
            <Header />
            <div className="appinfo-body">
                <div className="appinfo-main shadow-inner">
                    <style
                        dangerouslySetInnerHTML={{
                            __html:
                                "\n        [data-custom-class='body'],\n        [data-custom-class='body'] * {\n            background: transparent !important;\n        }\n\n        [data-custom-class='title'],\n        [data-custom-class='title'] * {\n            font-family: Arial !important;\n            font-size: 26px !important;\n            color: #000000 !important;\n        }\n\n        [data-custom-class='subtitle'],\n        [data-custom-class='subtitle'] * {\n            font-family: Arial !important;\n            color: #595959 !important;\n            font-size: 14px !important;\n        }\n\n        [data-custom-class='heading_1'],\n        [data-custom-class='heading_1'] * {\n            font-family: Arial !important;\n            font-size: 19px !important;\n            color: #000000 !important;\n        }\n\n        [data-custom-class='heading_2'],\n        [data-custom-class='heading_2'] * {\n            font-family: Arial !important;\n            font-size: 17px !important;\n            color: #000000 !important;\n        }\n\n        [data-custom-class='body_text'],\n        [data-custom-class='body_text'] * {\n            color: #595959 !important;\n            font-size: 14px !important;\n            font-family: Arial !important;\n        }\n\n        [data-custom-class='link'],\n        [data-custom-class='link'] * {\n            color: #3030F1 !important;\n            font-size: 14px !important;\n            font-family: Arial !important;\n            word-break: break-word !important;\n        }\n    "
                        }}
                    />
                    <div data-custom-class="body">
                        <div>
                            <strong>
                                <span style={{ fontSize: 26 }}>
                                    <span data-custom-class="title">PRIVACY NOTICE</span>
                                </span>
                            </strong>
                        </div>
                        <div>
                            <br />
                        </div>
                        <div>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <strong>
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="subtitle">
                                            Last updated <bdt className="question">February 01, 2022</bdt>
                                        </span>
                                    </span>
                                </strong>
                            </span>
                        </div>
                        <div>
                            <br />
                        </div>
                        <div>
                            <br />
                        </div>
                        <div>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        Thank you for choosing to be part of our community at{" "}
                                        <bdt className="question">Fellowapp HR Solutions(OPC) Pvt Ltd</bdt>
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                            </span>
                                        </span>{" "}
                                        ("
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                        <strong>Company</strong>
                                    </span>
                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor">
                                                            <span data-custom-class="body_text" />
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                    <span data-custom-class="body_text">
                                        ," "<strong>we</strong>," "<strong>us</strong>," or "
                                        <strong>our</strong>"). We are committed to protecting your personal
                                        information and your right to privacy. If you have any questions or
                                        concerns about this privacy notice or our practices with regard to
                                        your personal information, please contact us at{" "}
                                        <bdt className="question">himanshu@recruitingmonk.com</bdt>.
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span data-custom-class="body_text">
                                        This privacy notice describes how we might use your information if
                                        you:
                                    </span>
                                </span>
                            </span>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <ul>
                            <li style={{ lineHeight: "1.5" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            Visit our website
                                            <bdt className="block-component" /> at{" "}
                                            <bdt className="question">
                                                <a
                                                    href="http://www.recruitingmonk.com"
                                                    target="_blank" rel="noreferrer"
                                                    data-custom-class="link"
                                                >
                                                    http://www.recruitingmonk.com
                                                </a>
                                            </bdt>
                                            <span style={{ fontSize: 15 }}>
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <div>
                            <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ fontSize: 15 }}>
                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                <div>
                                    <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                            <span style={{ fontSize: 15 }}>
                                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                                    <span data-custom-class="body_text">
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <div style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: 15 }}>
                                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                                    <span data-custom-class="body_text">
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                        <ul>
                                            <li style={{ lineHeight: "1.5" }}>
                                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span data-custom-class="body_text">
                                                            Engage with us in other related ways ― including any
                                                            sales, marketing, or events
                                                            <span style={{ fontSize: 15 }}>
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    <span data-custom-class="body_text">
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: 15 }}>
                                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                                    <span data-custom-class="body_text">
                                                        In this privacy notice, if we refer to:
                                                    </span>
                                                </span>
                                            </span>
                                            <span style={{ fontSize: 15 }}>
                                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                                    <span data-custom-class="body_text">
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                        <ul>
                                            <li style={{ lineHeight: "1.5" }}>
                                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span data-custom-class="body_text">
                                                            "<strong>Website</strong>," we are referring to any
                                                            website of ours that references or links to this policy
                                                            <span style={{ fontSize: 15 }}>
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    <span data-custom-class="body_text">
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </li>
                                        </ul>
                                        <div>
                                            <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span data-custom-class="body_text">
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                                <div>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span style={{ fontSize: 15 }}>
                                                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                    <span data-custom-class="body_text">
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </bdt>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    "<strong>Services</strong>," we are referring to our
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>{" "}
                                                                    Website,
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span
                                                                                                    style={{ color: "rgb(89, 89, 89)" }}
                                                                                                >
                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                        <bdt className="block-component"></bdt>
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span style={{ fontSize: 15 }}>
                                                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                <span data-custom-class="body_text">
                                                                    {" "}
                                                                    and other related services, including any sales,
                                                                    marketing, or events
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span
                                                                                                    style={{ color: "rgb(89, 89, 89)" }}
                                                                                                >
                                                                                                    <bdt className="statement-end-if-in-editor"></bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span data-custom-class="body_text">
                                                                The purpose of this privacy notice is to explain to you
                                                                in the clearest way possible what information we
                                                                collect, how we use it, and what rights you have in
                                                                relation to it. If there are any terms in this privacy
                                                                notice that you do not agree with, please discontinue
                                                                use of our Services immediately.
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        Please read this privacy notice carefully, as it
                                                                        will help you understand what we do with the
                                                                        information that we collect.
                                                                    </strong>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                                                <strong>
                                                                    <span data-custom-class="heading_1">
                                                                        TABLE OF CONTENTS
                                                                    </span>
                                                                </strong>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <a data-custom-class="link" href="#infocollect">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                1. WHAT INFORMATION DO WE COLLECT?
                                                            </span>
                                                        </a>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <a data-custom-class="link" href="#infouse">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                2. HOW DO WE USE YOUR INFORMATION?
                                                            </span>
                                                        </a>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <a data-custom-class="link" href="#infoshare">
                                                                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                                                            </a>
                                                        </span>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <a data-custom-class="link" href="#whoshare">
                                                                4. WHO WILL YOUR INFORMATION BE SHARED WITH?
                                                            </a>
                                                        </span>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <a data-custom-class="link" href="#cookies">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                                                            </span>
                                                        </a>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <bdt className="block-component" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <a data-custom-class="link" href="#sociallogins">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </a>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                        </span>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <a data-custom-class="link" href="#inforetain">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                7. HOW LONG DO WE KEEP YOUR INFORMATION?
                                                            </span>
                                                        </a>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <a data-custom-class="link" href="#infosafe">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                                            </span>
                                                        </a>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <a data-custom-class="link" href="#infominors">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                9. DO WE COLLECT INFORMATION FROM MINORS?
                                                            </span>
                                                        </a>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <a data-custom-class="link" href="#privacyrights">
                                                                10. WHAT ARE YOUR PRIVACY RIGHTS?
                                                            </a>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <a data-custom-class="link" href="#DNT">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                11. CONTROLS FOR DO-NOT-TRACK FEATURES
                                                            </span>
                                                        </a>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <a data-custom-class="link" href="#caresidents">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                                                                RIGHTS?
                                                            </span>
                                                        </a>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <a data-custom-class="link" href="#policyupdates">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                13. DO WE MAKE UPDATES TO THIS NOTICE?
                                                            </span>
                                                        </a>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <a data-custom-class="link" href="#contact">
                                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                            14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                                        </span>
                                                    </a>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <a data-custom-class="link" href="#request">
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            15. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE
                                                            COLLECT FROM YOU?
                                                        </span>
                                                    </a>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div id="infocollect" style={{ lineHeight: "1.5" }}>
                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                                        <strong>
                                                                            <span data-custom-class="heading_1">
                                                                                1. WHAT INFORMATION DO WE COLLECT?
                                                                            </span>
                                                                        </strong>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span
                                                        data-custom-class="heading_2"
                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                    >
                                                        <span style={{ fontSize: 15 }}>
                                                            <strong>Personal information you disclose to us</strong>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <div>
                                                        <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short:</em>
                                                                                </strong>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em></em>
                                                                                </strong>
                                                                                <em>
                                                                                    We collect personal information that you
                                                                                    provide to us.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                We collect personal information that you voluntarily
                                                                provide to us when you{" "}
                                                                <span style={{ fontSize: 15 }}>
                                                                    <bdt className="block-component" />
                                                                </span>
                                                                register on the{" "}
                                                            </span>
                                                            <span style={{ fontSize: 15 }}>
                                                                <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                    Website, <bdt className="block-component"></bdt>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                    express an interest in obtaining information about us
                                                                    or our products and Services, when you participate in
                                                                    activities on the
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <bdt className="block-component" />
                                                                                    Website<bdt className="block-component"></bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <bdt className="block-component" />
                                                                </span>
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                                {" "}
                                                                or otherwise when you contact us.
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                The personal information that we collect depends on the
                                                                context of your interactions with us and the{" "}
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="block-component" />
                                                                                                Website
                                                                                                <bdt className="block-component"></bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <span style={{ fontSize: 15 }}>
                                                                    , the choices you make and the products and features
                                                                    you use. The personal information we collect may
                                                                    include the following:
                                                                    <bdt className="block-component" />
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <strong>Personal Information Provided by You.</strong>{" "}
                                                                We collect{" "}
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="forloop-component" />
                                                                        <bdt className="question">names</bdt>;
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="forloop-component" />
                                                                        <bdt className="question">phone numbers</bdt>;
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="forloop-component" />
                                                                        <bdt className="question">email addresses</bdt>;
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="forloop-component" />
                                                                        <bdt className="question">usernames</bdt>;
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="forloop-component" />
                                                                        and other similar information.
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <strong>Payment Data.</strong> We may collect data
                                                                necessary to process your payment if you make purchases,
                                                                such as your payment instrument number (such as a credit
                                                                card number), and the security code associated with your
                                                                payment instrument. All payment data is stored by
                                                                <bdt className="forloop-component" />
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <bdt className="block-component" />
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <bdt className="question">Razorpay</bdt>
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="block-component" />
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span
                                                                                                style={{ color: "rgb(89, 89, 89)" }}
                                                                                            >
                                                                                                <span data-custom-class="body_text">
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            <bdt className="forloop-component"></bdt>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                            . You may find their privacy notice link(s) here:
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                        <bdt className="forloop-component" />
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <span data-custom-class="body_text">
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <bdt className="block-component" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                            <bdt className="question">
                                                                                <a
                                                                                    href="https://razorpay.com/privacy/"
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                    data-custom-class="link"
                                                                                >
                                                                                    https://razorpay.com/privacy/
                                                                                </a>
                                                                            </bdt>
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component" />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                        <bdt className="forloop-component" />
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                .
                                                                                            </span>
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                    <bdt className="block-component" />
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <strong>Social Media Login Data. </strong>We may provide
                                                                you with the option to register with us using your
                                                                existing social media account details, like your
                                                                Facebook, Twitter or other social media account. If you
                                                                choose to register in this way, we will collect the
                                                                information described in the section called "
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span data-custom-class="body_text">
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <a
                                                                                    data-custom-class="link"
                                                                                    href="#sociallogins"
                                                                                >
                                                                                    HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                                                                                </a>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                " below.
                                                                <span style={{ fontSize: 15 }}>
                                                                    <bdt className="statement-end-if-in-editor">
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                    </bdt>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                All personal information that you provide to us must be
                                                                true, complete and accurate, and you must notify us of
                                                                any changes to such personal information.
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span
                                                        data-custom-class="heading_2"
                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                    >
                                                        <span style={{ fontSize: 15 }}>
                                                            <strong>Information automatically collected</strong>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <div>
                                                        <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short:</em>
                                                                                </strong>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em> </em>
                                                                                </strong>
                                                                                <em>
                                                                                    Some information — such as your Internet
                                                                                    Protocol (IP) address and/or browser and
                                                                                    device characteristics — is collected
                                                                                    automatically when you visit our{" "}
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <bdt className="block-component" />
                                                                                                                    Website
                                                                                                                    <bdt className="block-component"></bdt>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                    .
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                We automatically collect certain information when you
                                                                visit, use or navigate the{" "}
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="block-component" />
                                                                                                Website
                                                                                                <bdt className="block-component"></bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                . This information does not reveal your specific
                                                                identity (like your name or contact information) but may
                                                                include device and usage information, such as your IP
                                                                address, browser and device characteristics, operating
                                                                system, language preferences, referring URLs, device
                                                                name, country, location, information about how and when
                                                                you use our{" "}
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="block-component" />
                                                                                                Website
                                                                                                <bdt className="block-component"></bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>{" "}
                                                                and other technical information. This information is
                                                                primarily needed to maintain the security and operation
                                                                of our{" "}
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="block-component" />
                                                                                                Website
                                                                                                <bdt className="block-component"></bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                , and for our internal analytics and reporting purposes.
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                Like many businesses, we also collect information
                                                                through cookies and similar technologies.{" "}
                                                                <bdt className="block-component" />
                                                                <bdt className="block-component" />
                                                                <bdt className="statement-end-if-in-editor">
                                                                    <span data-custom-class="body_text" />
                                                                </bdt>
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                The information we collect includes:
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <em>Log and Usage Data.</em> Log and usage data is
                                                                    service-related, diagnostic, usage and performance
                                                                    information our servers automatically collect when you
                                                                    access or use our{" "}
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                    Website
                                                                                                    <bdt className="block-component"></bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    and which we record in log files. Depending on how you
                                                                    interact with us, this log data may include your IP
                                                                    address, device information, browser type and settings
                                                                    and information about your activity in the{" "}
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                    Website
                                                                                                    <bdt className="block-component"></bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <span style={{ fontSize: 15 }}> </span>(such as the
                                                                    date/time stamps associated with your usage, pages and
                                                                    files viewed, searches and other actions you take such
                                                                    as which features you use), device event information
                                                                    (such as system activity, error reports (sometimes
                                                                    called 'crash dumps') and hardware settings).
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <em>Device Data.</em> We collect device data such as
                                                                    information about your computer, phone, tablet or
                                                                    other device you use to access the{" "}
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                    Website
                                                                                                    <bdt className="block-component"></bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    . Depending on the device used, this device data may
                                                                    include information such as your IP address (or proxy
                                                                    server), device and application identification
                                                                    numbers, location, browser type, hardware model
                                                                    Internet service provider and/or mobile carrier,
                                                                    operating system and system configuration information.
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <em>Location Data.</em> We collect location data such
                                                                    as information about your device's location, which can
                                                                    be either precise or imprecise. How much information
                                                                    we collect depends on the type and settings of the
                                                                    device you use to access the{" "}
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                    Website
                                                                                                    <bdt className="block-component"></bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    . For example, we may use GPS and other technologies
                                                                    to collect geolocation data that tells us your current
                                                                    location (based on your IP address). You can opt out
                                                                    of allowing us to collect this information either by
                                                                    refusing access to the information or by disabling
                                                                    your Location setting on your device. Note however, if
                                                                    you choose to opt out, you may not be able to use
                                                                    certain aspects of the Services.
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                    <bdt className="statement-end-if-in-editor" />
                                                    <span data-custom-class="body_text">
                                                        <span style={{ fontSize: 15 }}>
                                                            <bdt className="statement-end-if-in-editor">
                                                                <bdt className="statement-end-if-in-editor">
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text">
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                            <bdt className="block-component" />
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <span
                                                                        style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <span
                                                                                style={{
                                                                                    color: "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                        <bdt className="block-component" />
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span
                                                        data-custom-class="heading_2"
                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                    >
                                                        <span style={{ fontSize: 15 }}>
                                                            <strong>Information collected from other sources</strong>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <div>
                                                        <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short: </em>
                                                                                </strong>
                                                                                <em>
                                                                                    We may collect limited data from public
                                                                                    databases, marketing partners,{" "}
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <em>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                    social media platforms,
                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                </span>
                                                                                            </em>
                                                                                        </span>
                                                                                    </span>
                                                                                    and other outside sources.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                In order to enhance our ability to provide relevant
                                                                marketing, offers and services to you and update our
                                                                records, we may obtain information about you from other
                                                                sources, such as public databases, joint marketing
                                                                partners, affiliate programs, data providers,
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="block-component" /> social media
                                                                            platforms,
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                    </span>
                                                                </span>{" "}
                                                                as well as from other third parties. This information
                                                                includes mailing addresses, job titles, email addresses,
                                                                phone numbers, intent data (or user behavior data),
                                                                Internet Protocol (IP) addresses, social media profiles,
                                                                social media URLs and custom profiles, for purposes of
                                                                targeted advertising and event promotion.
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text">
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                    </span>
                                                                                </span>{" "}
                                                                            </span>
                                                                            If you interact with us on a social media platform
                                                                            using your social media account (e.g. Facebook or
                                                                            Twitter), we receive personal information about
                                                                            you such as your name, email address, and gender.
                                                                            Any personal information that we collect from your
                                                                            social media account depends on your social media
                                                                            account's privacy settings.
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <bdt className="block-component">
                                                                                        <span
                                                                                            style={{
                                                                                                color: "rgb(89, 89, 89)",
                                                                                                fontSize: 15
                                                                                            }}
                                                                                        >
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "rgb(89, 89, 89)",
                                                                                                        fontSize: 15
                                                                                                    }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                        </bdt>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                            <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div id="infouse" style={{ lineHeight: "1.5" }}>
                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                                        <strong>
                                                                            <span data-custom-class="heading_1">
                                                                                2. HOW DO WE USE YOUR INFORMATION?
                                                                            </span>
                                                                        </strong>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <br />
                                                    </div>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short: </em>
                                                                                </strong>
                                                                                <em>
                                                                                    We process your information for purposes based
                                                                                    on legitimate business interests, the
                                                                                    fulfillment of our contract with you,
                                                                                    compliance with our legal obligations, and/or
                                                                                    your consent.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                We use personal information collected via our{" "}
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="block-component" />
                                                                                                Website
                                                                                                <bdt className="block-component"></bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>{" "}
                                                                for a variety of business purposes described below. We
                                                                process your personal information for these purposes in
                                                                reliance on our legitimate business interests, in order
                                                                to enter into or perform a contract with you, with your
                                                                consent, and/or for compliance with our legal
                                                                obligations. We indicate the specific processing grounds
                                                                we rely on next to each purpose listed below.
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                We use the information we collect or receive:
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        To facilitate account creation and logon process.
                                                                    </strong>{" "}
                                                                    If you choose to link your account with us to a
                                                                    third-party account (such as your Google or Facebook
                                                                    account), we use the information you allowed us to
                                                                    collect from those third parties to facilitate account
                                                                    creation and logon process for the performance of the
                                                                    contract.
                                                                    <span
                                                                        style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                    >
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <bdt className="block-component" />
                                                                                </span>
                                                                            </span>{" "}
                                                                        </span>
                                                                    </span>
                                                                    See the section below headed "
                                                                    <span
                                                                        style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <a data-custom-class="link" href="#sociallogins">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                                                                                    </span>
                                                                                </span>
                                                                            </a>
                                                                        </span>
                                                                    </span>
                                                                    " for further information.
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span
                                                                                                    style={{ color: "rgb(89, 89, 89)" }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15,
                                                                                                                                    color:
                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            fontSize: 15
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </bdt>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>To post testimonials.</strong> We post
                                                                    testimonials on our{" "}
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                    Website
                                                                                                    <bdt className="block-component"></bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    that may contain personal information. Prior to
                                                                    posting a testimonial, we will obtain your consent to
                                                                    use your name and the content of the testimonial. If
                                                                    you wish to update, or delete your testimonial, please
                                                                    contact us at{" "}
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                                <bdt className="question">__________</bdt>
                                                                                <bdt className="else-block" />
                                                                            </span>
                                                                        </span>
                                                                    </span>{" "}
                                                                    and be sure to include your name, testimonial
                                                                    location, and contact information.
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span
                                                                                                    style={{ color: "rgb(89, 89, 89)" }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <bdt className="statement-end-if-in-editor"></bdt>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>Request feedback. </strong>We may use your
                                                                    information to request feedback and to contact you
                                                                    about your use of our{" "}
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                    Website
                                                                                                    <bdt className="block-component"></bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                                .
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        To enable user-to-user communications.
                                                                    </strong>{" "}
                                                                    We may use your information in order to enable
                                                                    user-to-user communications with each user's consent.
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span
                                                                                                    style={{ color: "rgb(89, 89, 89)" }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>To manage user accounts. </strong>We may use
                                                                    your information for the purposes of managing our
                                                                    account and keeping it in working order.
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <bdt className="block-component" />
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        To send administrative information to you.{" "}
                                                                    </strong>
                                                                    We may use your personal information to send you
                                                                    product, service and new feature information and/or
                                                                    information about changes to our terms, conditions,
                                                                    and policies.
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <bdt className="block-component" />
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>To protect our Services. </strong>We may use
                                                                    your information as part of our efforts to keep our{" "}
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                    Website
                                                                                                    <bdt className="block-component"></bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    safe and secure (for example, for fraud monitoring and
                                                                    prevention).
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <bdt className="block-component" />
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        To enforce our terms, conditions and policies for
                                                                        business purposes, to comply with legal and
                                                                        regulatory requirements or in connection with our
                                                                        contract.
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </strong>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <bdt className="block-component" />
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15 }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    <span data-custom-class="body_text">
                                                                        <strong>
                                                                            To respond to legal requests and prevent harm.{" "}
                                                                        </strong>
                                                                        If we receive a subpoena or other legal request, we
                                                                        may need to inspect the data we hold to determine
                                                                        how to respond.
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <span
                                                                                                style={{ color: "rgb(89, 89, 89)" }}
                                                                                            >
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p style={{ fontSize: 15 }}>
                                                    <bdt className="block-component" />
                                                </p>
                                                <p style={{ fontSize: 15 }}>
                                                    <bdt className="block-component" />
                                                </p>
                                                <p style={{ fontSize: 15 }}>
                                                    <bdt className="block-component" />
                                                </p>
                                                <p style={{ fontSize: 15 }}>
                                                    <bdt className="block-component" />
                                                </p>
                                                <div>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        To send you marketing and promotional
                                                                        communications.
                                                                    </strong>{" "}
                                                                    We and/or our third-party marketing partners may use
                                                                    the personal information you send to us for our
                                                                    marketing purposes, if this is in accordance with your
                                                                    marketing preferences. For example, when expressing an
                                                                    interest in obtaining information about us or our{" "}
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                    Website
                                                                                                    <bdt className="block-component"></bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    , subscribing to marketing or otherwise contacting us,
                                                                    we will collect personal information from you. You can
                                                                    opt-out of our marketing emails at any time (see the "
                                                                    <a data-custom-class="link" href="#privacyrights">
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                WHAT ARE YOUR PRIVACY RIGHTS?
                                                                            </span>
                                                                        </span>
                                                                    </a>
                                                                    " below).
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span
                                                                                                    style={{ color: "rgb(89, 89, 89)" }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>Deliver targeted advertising to you.</strong>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                    {" "}
                                                                    We may use your information to develop and display
                                                                    personalized content and advertising (and work with
                                                                    third parties who do so) tailored to your interests
                                                                    and/or location and to measure its effectiveness.
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>For other business purposes.</strong>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                    {" "}
                                                                    We may use your information for other business
                                                                    purposes, such as data analysis, identifying usage
                                                                    trends, determining the effectiveness of our
                                                                    promotional campaigns and to evaluate and improve our
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                    Website
                                                                                                    <bdt className="block-component"></bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    , products, marketing and your experience. We may use
                                                                    and store this information in aggregated and
                                                                    anonymized form so that it is not associated with
                                                                    individual end users and does not include personal
                                                                    information.
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: 15 }}>
                                                        <bdt className="block-component" />
                                                    </span>
                                                    <bdt className="statement-end-if-in-editor">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div id="infoshare" style={{ lineHeight: "1.5" }}>
                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                                                        <strong>
                                                                            <span data-custom-class="heading_1">
                                                                                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                                                                            </span>
                                                                        </strong>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <strong>
                                                                    <em>In Short:</em>
                                                                </strong>
                                                                <em>
                                                                    {" "}
                                                                    We only share information with your consent, to comply
                                                                    with laws, to provide you with services, to protect
                                                                    your rights, or to fulfill business obligations.
                                                                </em>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                We may process or share your data that we hold based on
                                                                the following legal basis:
                                                                <bdt className="block-component"></bdt>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>Consent:</strong> We may process your data if
                                                                    you have given us specific consent to use your
                                                                    personal information for a specific purpose.
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="statement-end-if-in-editor">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="block-component" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>Legitimate Interests:</strong> We may process
                                                                    your data when it is reasonably necessary to achieve
                                                                    our legitimate business interests.
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>Performance of a Contract:</strong> Where we
                                                                    have entered into a contract with you, we may process
                                                                    your personal information to fulfill the terms of our
                                                                    contract.
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>Legal Obligations:</strong> We may disclose
                                                                    your information where we are legally required to do
                                                                    so in order to comply with applicable law,
                                                                    governmental requests, a judicial proceeding, court
                                                                    order, or legal process, such as in response to a
                                                                    court order or a subpoena (including in response to
                                                                    public authorities to meet national security or law
                                                                    enforcement requirements).
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>Vital Interests:</strong> We may disclose your
                                                                    information where we believe it is necessary to
                                                                    investigate, prevent, or take action regarding
                                                                    potential violations of our policies, suspected fraud,
                                                                    situations involving potential threats to the safety
                                                                    of any person and illegal activities, or as evidence
                                                                    in litigation in which we are involved.
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                More specifically, we may need to process your data or
                                                                share your personal information in the following
                                                                situations:
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>Business Transfers.</strong> We may share or
                                                                    transfer your information in connection with, or
                                                                    during negotiations of, any merger, sale of company
                                                                    assets, financing, or acquisition of all or a portion
                                                                    of our business to another company.
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <div>
                                                        <span style={{ fontSize: 15 }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <ul>
                                                        <li style={{ lineHeight: "1.5" }}>
                                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                        <strong>
                                                                            Vendors, Consultants and Other Third-Party Service
                                                                            Providers.
                                                                        </strong>{" "}
                                                                        We may share your data with third-party vendors,
                                                                        service providers, contractors or agents who perform
                                                                        services for us or on our behalf and require access
                                                                        to such information to do that work. Examples
                                                                        include: payment processing, data analysis, email
                                                                        delivery, hosting services, customer service and
                                                                        marketing efforts. We may allow selected third
                                                                        parties to use tracking technology on the{" "}
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <bdt className="block-component" />
                                                                                                        Website
                                                                                                        <bdt className="block-component"></bdt>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                        , which will enable them to collect data on our
                                                                        behalf about how you interact with our
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <bdt className="block-component" />
                                                                                                        Website
                                                                                                        <bdt className="block-component"></bdt>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                        over time. This information may be used to, among
                                                                        other things, analyze and track data, determine the
                                                                        popularity of certain content, pages or features,
                                                                        and better understand online activity. Unless
                                                                        described in this notice, we do not share, sell,
                                                                        rent or trade any of your information with third
                                                                        parties for their promotional purposes.{" "}
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <bdt className="block-component" />
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <div>
                                                        <span style={{ fontSize: 15 }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                    <bdt className="block-component">
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </bdt>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <div>
                                                            <span style={{ fontSize: 15 }}>
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <ul>
                                                            <li style={{ lineHeight: "1.5" }}>
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <strong>Affiliates.</strong> We may share your
                                                                            information with our affiliates, in which case we
                                                                            will require those affiliates to honor this
                                                                            privacy notice. Affiliates include our parent
                                                                            company and any subsidiaries, joint venture
                                                                            partners or other companies that we control or
                                                                            that are under common control with us.
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span
                                                                                                style={{ color: "rgb(89, 89, 89)" }}
                                                                                            >
                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <span style={{ fontSize: 15 }}>
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <ul>
                                                            <li style={{ lineHeight: "1.5" }}>
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <strong>Business Partners. </strong>We may share
                                                                            your information with our business partners to
                                                                            offer you certain products, services or
                                                                            promotions.
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span data-custom-class="body_text">
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <span style={{ fontSize: 15 }}>
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </span>
                                                            </span>
                                                            <div>
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="whoshare" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                4. WHO WILL YOUR INFORMATION BE SHARED
                                                                                                WITH?
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </span>{" "}
                                                                            </span>{" "}
                                                                        </span>{" "}
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short:</em>
                                                                                </strong>
                                                                                <em>
                                                                                    {" "}
                                                                                    We only share information with the following{" "}
                                                                                    <bdt className="block-component" />
                                                                                    third parties.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            We only share and disclose your
                                                                                            information with the following{" "}
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                <bdt className="block-component" />
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            third parties. If we have processed your
                                                                                            data based on your consent and you wish to
                                                                                            revoke your consent, please contact us
                                                                                            using the contact details provided in the
                                                                                            section below titled "
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <a
                                                                                                            data-custom-class="link"
                                                                                                            href="#contact"
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                HOW CAN YOU CONTACT US ABOUT
                                                                                                                THIS NOTICE?
                                                                                                            </span>
                                                                                                        </a>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                            ".
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                <bdt className="block-component" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <span data-custom-class="body_text" />
                                                                    <span data-custom-class="body_text" />
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <span data-custom-class="body_text" />
                                                                    <span data-custom-class="body_text" />
                                                                    <span data-custom-class="body_text" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <span data-custom-class="body_text" />
                                                                    <span data-custom-class="body_text" />
                                                                    <span data-custom-class="body_text" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <span data-custom-class="body_text" />
                                                                    <span data-custom-class="body_text" />
                                                                    <span data-custom-class="body_text" />
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span
                                                                        style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                    >
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <bdt className="block-component">
                                                                                            <span data-custom-class="heading_1" />
                                                                                        </bdt>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="cookies" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                5. DO WE USE COOKIES AND OTHER TRACKING
                                                                                                TECHNOLOGIES?
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short: </em>
                                                                                </strong>
                                                                                <em>
                                                                                    {" "}
                                                                                    We may use cookies and other tracking
                                                                                    technologies to collect and store your
                                                                                    information.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                We may use cookies and similar tracking
                                                                                technologies (like web beacons and pixels) to
                                                                                access or store information. Specific
                                                                                information about how we use such technologies
                                                                                and how you can refuse certain cookies is set
                                                                                out in our Cookie Notice
                                                                                <span
                                                                                    style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />.
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            color: "rgb(89, 89, 89)",
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span
                                                                                                style={{ color: "rgb(89, 89, 89)" }}
                                                                                            >
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span
                                                                                                        style={{ color: "rgb(89, 89, 89)" }}
                                                                                                    >
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            color: "rgb(89, 89, 89)",
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                color: "rgb(89, 89, 89)",
                                                                                                fontSize: 15
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span
                                                                                                    style={{ color: "rgb(89, 89, 89)" }}
                                                                                                >
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="sociallogins" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                                                                                            </span>
                                                                                        </strong>{" "}
                                                                                    </span>
                                                                                </span>
                                                                            </span>{" "}
                                                                        </span>{" "}
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short: </em>
                                                                                </strong>
                                                                                <em>
                                                                                    If you choose to register or log in to our
                                                                                    services using a social media account, we may
                                                                                    have access to certain information about you.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                Our
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>{" "}
                                                                                offers you the ability to register and login
                                                                                using your third-party social media account
                                                                                details (like your Facebook or Twitter logins).
                                                                                Where you choose to do this, we will receive
                                                                                certain profile information about you from your
                                                                                social media provider. The profile information
                                                                                we receive may vary depending on the social
                                                                                media provider concerned, but will often include
                                                                                your name, email address, friends list, profile
                                                                                picture as well as other information you choose
                                                                                to make public on such social media platform.{" "}
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="block-component" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                We will use the information we receive only for
                                                                                the purposes that are described in this privacy
                                                                                notice or that are otherwise made clear to you
                                                                                on the relevant{" "}
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                . Please note that we do not control, and are
                                                                                not responsible for, other uses of your personal
                                                                                information by your third-party social media
                                                                                provider. We recommend that you review their
                                                                                privacy notice to understand how they collect,
                                                                                use and share your personal information, and how
                                                                                you can set your privacy preferences on their
                                                                                sites and apps.
                                                                                <span
                                                                                    style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            color: "rgb(89, 89, 89)",
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                color: "rgb(89, 89, 89)",
                                                                                                fontSize: 15
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "rgb(89, 89, 89)",
                                                                                                    fontSize: 15
                                                                                                }}
                                                                                            >
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span
                                                                                                        style={{ color: "rgb(89, 89, 89)" }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <bdt className="statement-end-if-in-editor"></bdt>
                                                                                                                </span>
                                                                                                                <bdt className="block-component">
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component">
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    color:
                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                    fontSize: 15
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                            fontSize: 15
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component"></bdt>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="inforetain" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                7. HOW LONG DO WE KEEP YOUR INFORMATION?
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short: </em>
                                                                                </strong>
                                                                                <em>
                                                                                    {" "}
                                                                                    We keep your information for as long as
                                                                                    necessary to fulfill the purposes outlined in
                                                                                    this privacy notice unless otherwise required
                                                                                    by law.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                We will only keep your personal information for
                                                                                as long as it is necessary for the purposes set
                                                                                out in this privacy notice, unless a longer
                                                                                retention period is required or permitted by law
                                                                                (such as tax, accounting or other legal
                                                                                requirements). No purpose in this notice will
                                                                                require us keeping your personal information for
                                                                                longer than{" "}
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="block-component" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                <bdt className="block-component" />
                                                                                the period of time in which users have an
                                                                                account with us
                                                                                <bdt className="block-component" />
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="else-block" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                .
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                When we have no ongoing legitimate business need
                                                                                to process your personal information, we will
                                                                                either delete or anonymize such information, or,
                                                                                if this is not possible (for example, because
                                                                                your personal information has been stored in
                                                                                backup archives), then we will securely store
                                                                                your personal information and isolate it from
                                                                                any further processing until deletion is
                                                                                possible.
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <bdt className="block-component" />
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="infosafe" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short: </em>
                                                                                </strong>
                                                                                <em>
                                                                                    {" "}
                                                                                    We aim to protect your personal information
                                                                                    through a system of organizational and
                                                                                    technical security measures.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                We have implemented appropriate technical and
                                                                                organizational security measures designed to
                                                                                protect the security of any personal information
                                                                                we process. However, despite our safeguards and
                                                                                efforts to secure your information, no
                                                                                electronic transmission over the Internet or
                                                                                information storage technology can be guaranteed
                                                                                to be 100% secure, so we cannot promise or
                                                                                guarantee that hackers, cybercriminals, or other
                                                                                unauthorized third parties will not be able to
                                                                                defeat our security, and improperly collect,
                                                                                access, steal, or modify your information.
                                                                                Although we will do our best to protect your
                                                                                personal information, transmission of personal
                                                                                information to and from our{" "}
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>{" "}
                                                                                is at your own risk. You should only access the{" "}
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>{" "}
                                                                                within a secure environment.
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                                <span
                                                                                    style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="infominors" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                9. DO WE COLLECT INFORMATION FROM
                                                                                                MINORS?
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short:</em>
                                                                                </strong>
                                                                                <em>
                                                                                    {" "}
                                                                                    We do not knowingly collect data from or
                                                                                    market to children under 18 years of age.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                We do not knowingly solicit data from or market
                                                                                to children under 18 years of age. By using the{" "}
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                , you represent that you are at least 18 or that
                                                                                you are the parent or guardian of such a minor
                                                                                and consent to such minor dependent’s use of the{" "}
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                . If we learn that personal information from
                                                                                users less than 18 years of age has been
                                                                                collected, we will deactivate the account and
                                                                                take reasonable measures to promptly delete such
                                                                                data from our records. If you become aware of
                                                                                any data we may have collected from children
                                                                                under age 18, please contact us at{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                        <bdt className="question">__________</bdt>
                                                                                        <bdt className="else-block" />
                                                                                    </span>
                                                                                </span>
                                                                                .
                                                                                <span
                                                                                    style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            color: "rgb(89, 89, 89)",
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="privacyrights" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                10. WHAT ARE YOUR PRIVACY RIGHTS?
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short:</em>
                                                                                </strong>
                                                                                <em>
                                                                                    {" "}
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <em>
                                                                                                    <bdt className="block-component" />
                                                                                                </em>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                    You may review, change, or terminate your
                                                                                    account at any time.
                                                                                </em>
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <bdt className="block-component" />
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        {" "}
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                If you are a resident in the EEA or UK and you
                                                                                believe we are unlawfully processing your
                                                                                personal information, you also have the right to
                                                                                complain to your local data protection
                                                                                supervisory authority. You can find their
                                                                                contact details here:{" "}
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <span
                                                                                                style={{ color: "rgb(48, 48, 241)" }}
                                                                                            >
                                                                                                <span data-custom-class="body_text">
                                                                                                    <a
                                                                                                        data-custom-class="link"
                                                                                                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                                        rel="noopener noreferrer"
                                                                                                        target="_blank"
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                .
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                If you are a resident in Switzerland, the
                                                                                contact details for the data protection
                                                                                authorities are available here:{" "}
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <span
                                                                                                style={{ color: "rgb(48, 48, 241)" }}
                                                                                            >
                                                                                                <span data-custom-class="body_text">
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <a
                                                                                                            data-custom-class="link"
                                                                                                            href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                                            rel="noopener noreferrer"
                                                                                                            target="_blank"
                                                                                                        >
                                                                                                            https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                                                        </a>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                .
                                                                                <bdt className="block-component" />
                                                                                <bdt className="block-component" />
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        data-custom-class="heading_2"
                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                    >
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <strong>Account Information</strong>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <br />
                                                                    </div>
                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                            <span
                                                                                style={{
                                                                                    color: "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span data-custom-class="body_text">
                                                                                                If you would at any time like to review
                                                                                                or change the information in your
                                                                                                account or terminate your account, you
                                                                                                can:
                                                                                                <bdt className="forloop-component" />
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <ul>
                                                                        <li style={{ lineHeight: "1.5" }}>
                                                                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                                <span
                                                                                    style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="question">
                                                                                                        Log in to your account settings and
                                                                                                        update your user account.
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div>
                                                                        <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                            <span
                                                                                style={{
                                                                                    color: "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="forloop-component" />
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                    Upon your request to terminate your account,
                                                                                    we will deactivate or delete your account and
                                                                                    information from our active databases.
                                                                                    However, we may retain some information in our
                                                                                    files to prevent fraud, troubleshoot problems,
                                                                                    assist with any investigations, enforce our
                                                                                    Terms of Use and/or comply with applicable
                                                                                    legal requirements.
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span
                                                                                                    style={{ color: "rgb(89, 89, 89)" }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                <bdt className="block-component" />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <u>Cookies and similar technologies:</u>
                                                                                </strong>{" "}
                                                                                Most Web browsers are set to accept cookies by
                                                                                default. If you prefer, you can usually choose
                                                                                to set your browser to remove cookies and to
                                                                                reject cookies. If you choose to remove cookies
                                                                                or reject cookies, this could affect certain
                                                                                features or services of our{" "}
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                . To opt-out of interest-based advertising by
                                                                                advertisers on our{" "}
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>{" "}
                                                                                visit
                                                                                <span style={{ color: "rgb(48, 48, 241)" }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <a
                                                                                            data-custom-class="link"
                                                                                            href="http://www.aboutads.info/choices/"
                                                                                            rel="noopener noreferrer"
                                                                                            target="_blank"
                                                                                        >
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                http://www.aboutads.info/choices/
                                                                                            </span>
                                                                                        </a>
                                                                                    </span>
                                                                                </span>
                                                                                .
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="block-component" />
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span
                                                                                                    style={{ color: "rgb(89, 89, 89)" }}
                                                                                                >
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="statement-end-if-in-editor"></bdt>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="block-component" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <u>Opting out of email marketing:</u>
                                                                                </strong>
                                                                                You can unsubscribe from our marketing email
                                                                                list at any time by clicking on the unsubscribe
                                                                                link in the emails that we send or by contacting
                                                                                us using the details provided below. You will
                                                                                then be removed from the marketing email list —
                                                                                however, we may still communicate with you, for
                                                                                example to send you service-related emails that
                                                                                are necessary for the administration and use of
                                                                                your account, to respond to service requests, or
                                                                                for other non-marketing purposes. To otherwise
                                                                                opt-out, you may:
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <span data-custom-class="body_text">
                                                                                            <bdt className="forloop-component" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <ul>
                                                                    <li style={{ lineHeight: "1.5" }}>
                                                                        <bdt className="question">
                                                                            <span data-custom-class="body_text">
                                                                                Contact us using the contact information
                                                                                provided.
                                                                            </span>
                                                                        </bdt>
                                                                    </li>
                                                                </ul>
                                                                <div>
                                                                    <bdt className="forloop-component">
                                                                        <span data-custom-class="body_text">
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </bdt>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="DNT" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                11. CONTROLS FOR DO-NOT-TRACK FEATURES
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                Most web browsers and some mobile operating
                                                                                systems and mobile applications include a
                                                                                Do-Not-Track ("DNT") feature or setting you can
                                                                                activate to signal your privacy preference not
                                                                                to have data about your online browsing
                                                                                activities monitored and collected. At this
                                                                                stage no uniform technology standard for
                                                                                recognizing and implementing DNT signals has
                                                                                been finalized. As such, we do not currently
                                                                                respond to DNT browser signals or any other
                                                                                mechanism that automatically communicates your
                                                                                choice not to be tracked online. If a standard
                                                                                for online tracking is adopted that we must
                                                                                follow in the future, we will inform you about
                                                                                that practice in a revised version of this
                                                                                privacy notice.
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="caresidents" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                12. DO CALIFORNIA RESIDENTS HAVE
                                                                                                SPECIFIC PRIVACY RIGHTS?
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <strong>
                                                                                    <em>In Short: </em>
                                                                                </strong>
                                                                                <em>
                                                                                    {" "}
                                                                                    Yes, if you are a resident of California, you
                                                                                    are granted specific rights regarding access
                                                                                    to your personal information.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                California Civil Code Section 1798.83, also
                                                                                known as the "Shine The Light" law, permits our
                                                                                users who are California residents to request
                                                                                and obtain from us, once a year and free of
                                                                                charge, information about categories of personal
                                                                                information (if any) we disclosed to third
                                                                                parties for direct marketing purposes and the
                                                                                names and addresses of all third parties with
                                                                                which we shared personal information in the
                                                                                immediately preceding calendar year. If you are
                                                                                a California resident and would like to make
                                                                                such a request, please submit your request in
                                                                                writing to us using the contact information
                                                                                provided below.
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                If you are under 18 years of age, reside in
                                                                                California, and have a registered account with{" "}
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                the Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                , you have the right to request removal of
                                                                                unwanted data that you publicly post on the
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                . To request removal of such data, please
                                                                                contact us using the contact information
                                                                                provided below, and include the email address
                                                                                associated with your account and a statement
                                                                                that you reside in California. We will make sure
                                                                                the data is not publicly displayed on the{" "}
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                Website
                                                                                                                <bdt className="block-component"></bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                , but please be aware that the data may not be
                                                                                completely or comprehensively removed from all
                                                                                our systems (e.g. backups, etc.).
                                                                                <span
                                                                                    style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="policyupdates" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                13. DO WE MAKE UPDATES TO THIS NOTICE?
                                                                                            </span>
                                                                                        </strong>{" "}
                                                                                    </span>
                                                                                </span>
                                                                            </span>{" "}
                                                                        </span>{" "}
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <em>
                                                                                    <strong>In Short: </strong> Yes, we will
                                                                                    update this notice as necessary to stay
                                                                                    compliant with relevant laws.
                                                                                </em>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                We may update this privacy notice from time to
                                                                                time. The updated version will be indicated by
                                                                                an updated "Revised" date and the updated
                                                                                version will be effective as soon as it is
                                                                                accessible. If we make material changes to this
                                                                                privacy notice, we may notify you either by
                                                                                prominently posting a notice of such changes or
                                                                                by directly sending you a notification. We
                                                                                encourage you to review this privacy notice
                                                                                frequently to be informed of how we are
                                                                                protecting your information.
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="contact" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                14. HOW CAN YOU CONTACT US ABOUT THIS
                                                                                                NOTICE?
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </span>{" "}
                                                                            </span>{" "}
                                                                        </span>{" "}
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                If you have questions or comments about this
                                                                                notice, you may{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color: "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                    <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                        email us at{" "}
                                                                                        <bdt className="question">__________</bdt>
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                </span>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span data-custom-class="body_text">
                                                                                            {" "}
                                                                                            or by post to:
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="question">
                                                                                                    Fellowapp HR Solutions(OPC) Pvt Ltd
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="question">
                                                                                House No : 1 5th Cross, 17th Main road
                                                                            </bdt>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <bdt className="block-component" />
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        data-custom-class="body_text"
                                                                        style={{ fontSize: 15 }}
                                                                    >
                                                                        <bdt className="question">
                                                                            Aicobonagar, near udupi garden park
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </bdt>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="question">Bangalore</bdt>
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <bdt className="block-component" />
                                                                                    <bdt className="block-component" />
                                                                                    <bdt className="question">560068</bdt>
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <bdt className="block-component" />
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text">
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                            <span
                                                                                                style={{ color: "rgb(89, 89, 89)" }}
                                                                                            >
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <bdt className="block-component">
                                                                                                            <bdt className="block-component" />
                                                                                                        </bdt>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </bdt>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            color: "rgb(89, 89, 89)",
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                    <bdt className="block-component" />
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div id="request" style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                                        <span
                                                                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15,
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        id="control"
                                                                                        style={{ color: "rgb(0, 0, 0)" }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span data-custom-class="heading_1">
                                                                                                15. HOW CAN YOU REVIEW, UPDATE, OR
                                                                                                DELETE THE DATA WE COLLECT FROM YOU?
                                                                                            </span>
                                                                                        </strong>{" "}
                                                                                    </span>{" "}
                                                                                </span>{" "}
                                                                            </span>{" "}
                                                                        </span>{" "}
                                                                    </span>
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <br />
                                                                </div>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span
                                                                        style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                Based on the applicable laws of your country,
                                                                                you may have the right to request access to the
                                                                                personal information we collect from you, change
                                                                                that information, or delete it in some
                                                                                circumstances. To request to review, update, or
                                                                                delete your personal information, please{" "}
                                                                                <bdt className="block-component"></bdt>submit a
                                                                                request form by clicking{" "}
                                                                            </span>
                                                                            <span style={{ color: "rgb(48, 48, 241)" }}>
                                                                                <span data-custom-class="body_text">
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <a
                                                                                            data-custom-class="link"
                                                                                            href="https://app.termly.io/notify/d89c70ee-b329-4f22-a65a-80cba1e01b20"
                                                                                            rel="noopener noreferrer"
                                                                                            target="_blank"
                                                                                        >
                                                                                            here
                                                                                        </a>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                            <bdt className="block-component">
                                                                                <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">.</span>
                                                                </div>
                                                                <style
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            "\n                            ul {\n                                list-style-type: square;\n                            }\n\n                            ul>li>ul {\n                                list-style-type: circle;\n                            }\n\n                            ul>li>ul>li>ul {\n                                list-style-type: square;\n                            }\n\n                            ol li {\n                                font-family: Arial;\n                            }\n                        "
                                                                    }}
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#595959",
                                                                    fontSize: 14,
                                                                    fontFamily: "Arial",
                                                                    paddingTop: 16
                                                                }}
                                                            >
                                                                This privacy policy was created using Termly's{" "}
                                                                <a
                                                                    style={{ color: "rgb(48, 48, 241) !important" }}
                                                                    href="https://termly.io/products/privacy-policy-generator"
                                                                >
                                                                    Privacy Policy Generator
                                                                </a>
                                                                .
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </bdt>
                                        </div>
                                    </bdt>
                                </div>
                            </bdt>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default Privacy;