import React, { useRef, useState } from "react";
import styles from "./MoreInfo.module.css";
import ReactPlayer from "react-player";

const MoreInfo = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null); // State to track hovered card

  const data = [
    "Guest lectures by top-tier company executives",
    "Diverse topics covering cutting-edge TA trends and strategies",
    "Opportunity to learn from and network with industry leaders",
    "Exclusive to Mini Diploma graduates",
  ];

  const cardData = [
    {
      video: `https://player.vimeo.com/video/1005896604?h=d77ca03551&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`,
      heading: "How to build and scale hiring plans? ",
      imageURL:
        "./images/userImg1.jpeg",
      name: "Savita Hortikar",
      designation: "Global Head TA (VP)",
      company: "@Fractal",
    },
    {
      video: `https://player.vimeo.com/video/1005896206?h=c2b9ae6dd0&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`,
      heading: "How perception can limit or expand your view? ",
      imageURL:
        "./images/userImg2.jpeg",
      name: "Prabhat Prasad",
      designation: "People & Culture Leader (VP)",
      company: "@Innovapptive Inc",
    },
    {
      video:
        "https://player.vimeo.com/video/1005897024?h=9656d777de&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      heading: "Synergy & Impact - Leadership Lessons ",
      imageURL:
        "./images/userImg3.jpeg",
      name: "Linish Theodore",
      designation: "AVP",
      company: "@Connect and Heal - CNH Care",
    },
    {
      video:
        "https://player.vimeo.com/video/1005889168?h=2053f7861c&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      heading: "How to address thousands of roles across geographies?",
      imageURL:
        "./images/userImg4.jpeg",
      name: "Koshel S.",
      designation: "Regional TA Head APAC",
      company: "@Sartorius",
    },
  ];

  const scrollRef = useRef(null);

  const handleLeftSlide = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const handleRightSlide = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={styles.moreinfo_container}>
      <div className={styles.moreinfo_subcontainer}>
        <div className={styles.moreinfo_heading}>
          The journey doesn't end after 12 weeks.
        </div>

        <div className={styles.moreinfo_subheading}>
          Post completion of Mini Diploma : Join our monthly Leadership
          Sessions, where industry titans and company leaders share their
          insights and experiences.
        </div>

        <div className={styles.moreinfo_content}>
          <div className={styles.moreinfo_content_heading}>
            What are Leadership Sessions ⭐ ?
          </div>

          <div className={styles.subheading_content_container}>
            {data.map((data, key) => (
              <div className={styles.moreinfo_subheadings} key={key}>
                <div className={styles.subheading_pointer}></div>
                <div className={styles.subheading_text}>{data}</div>
              </div>
            ))}
          </div>

          <div
            className={styles.moreinfo_content_heading}
            style={{ padding: "3rem 0 2rem 0" }}
          >
            Recent Leadership Session topics
          </div>

          <div className={styles.moreInfo_cards_contanier} ref={scrollRef}>
            {cardData.map((data, key) => (
              <div
                className={styles.moreinfo_card_container}
                key={key}
                onMouseEnter={() => setHoveredIndex(key)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <div className={styles.video_container}>
                  <ReactPlayer
                    url={data.video}
                    controls
                    width="250px"
                    height="200px"
                  />
                </div>
                <div
                  className={`${styles.moreInfo_card_content_heading} ${
                    hoveredIndex === key ? styles.full_heading : ""
                  }`}
                >
                  {data.heading}
                </div>

                <div className={styles.moreIfo_card_info_container}>
                  <div className={styles.moreinfo_image_container}>
                    <img
                      src={data.imageURL}
                      alt=""
                      style={{ width: "80px", borderRadius: "50%" }}
                    />
                  </div>

                  <div className={styles.moreInfo_text_container}>
                    <div className={styles.moreInfo_card_name}>{data.name}</div>
                    <div className={styles.moreInfo_card_designation}>
                      {data.designation}
                    </div>
                    <div className={styles.moreInfo_card_company}>
                      {data.company}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className={styles.slider_buttons_container}>
            <img
              src="/images/leftSlide.svg"
              alt=""
              onClick={handleLeftSlide}
              className={styles.slider_image}
            />
            <img
              src="/images/rightSlide.svg"
              alt=""
              onClick={handleRightSlide}
              className={styles.slider_image}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;
