import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux"

import DoubtAnswer from "./DoubtAnswer";
import { auth } from "../../firebase";
import {
    selectUserName,
    selectUserPhoto
} from "../../Providers/userSlice";

import { Grid, Hidden } from "@material-ui/core";
import { Button, TextField, Divider } from "@material-ui/core";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const DoubtReply = (props) => {
    const answers = props.answers;
    const doubt_id = props.d_id;
    const [doubtReply, setDoubtReply] = useState("");
    const [added, setAdded] = useState(false);
    const [addedReply, setAddedReply] = useState({});
    const userName = useSelector(selectUserName);
    const userPhoto = useSelector(selectUserPhoto);
    const history = useHistory();

    const handleDoubtReply = (e) => {
        setDoubtReply(e.target.value);
    }


    const postDoubtReply = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            try {
                                const response = await fetch(`https://elearning-monks.azurewebsites.net/users/doubts/post/reply`, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken,
                                        doubtID: doubt_id,
                                        answer: doubtReply
                                    })
                                });
                                const selectedDoubtData = await response.json();
                                if (selectedDoubtData.apiStatus && selectedDoubtData.apiStatus != "SUCCESS" && selectedDoubtData.apiStatus != "Success" && selectedDoubtData.apiStatus != "success") {
                                    history.push('/courses')
                                }
                                Swal.fire({
                                    title: "Your Reply is Posted!",
                                    icon: "success"
                                });
                                var d1 = new Date();
                                setAddedReply({
                                    name: userName,
                                    profilePhoto: userPhoto,
                                    answer: doubtReply,
                                    createdAt: d1
                                });
                                setAdded(true);
                                setDoubtReply("");
                            } catch (error) {
                                console.log(error)
                            }
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }

    }



    return (
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={12} sm={9} md={9}>
                    <div className="Reply-container" >
                        <TextField fullWidth multiline
                            value={doubtReply}
                            onChange={handleDoubtReply}
                            maxRows={2} className="Reply-field"
                            label="Reply" placeholder="Type Your Reply Here"
                            variant="outlined" />
                    </div>
                </Grid>
                <Hidden only={['lg', 'md', 'sm', 'xl']}><Grid item xs={9}></Grid></Hidden>
                <Grid item xs={3} sm={3} md={3}>
                    <div style={{ paddingTop: '20px' }} className="Reply-container-buttons">
                        <Button size="small" variant="contained" className="Submit-Reply"
                            disabled={doubtReply.length === 0 ? true : false}
                            onClick={postDoubtReply}
                        >Reply</Button>
                    </div>
                </Grid>
                <Grid container style={{ marginLeft: "15px" }}>
                    {
                        answers.length > 0 ?
                            <Grid item xs={12} className="replies-box">
                                {answers?.map((answer, i) => {
                                    return (
                                        <DoubtAnswer ansid={answer._id} key={i} />
                                    )
                                })
                                }

                                {
                                    added ?
                                        <DoubtAnswer answer={addedReply} />
                                        :
                                        null
                                }
                            </Grid>
                            :
                            added ?
                                <DoubtAnswer answer={addedReply} />
                                :
                                <Grid item xs={12} className="py-4" align="center">
                                    <p>No Replies Here yet!!</p>
                                    <Divider />
                                </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DoubtReply;