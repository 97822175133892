// Global imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Other Files Imports 
import { auth, provider } from "../../firebase";
import {
    selectUserName,
    setUserLoginDetails,
} from "../../Providers/userSlice";


// design Imports 
import { Dialog, DialogContent, Button } from "@material-ui/core";


const Login = (props) => {
    const dispatch = useDispatch();
    const userName = useSelector(selectUserName);
    const [open, setOpen] = useState(false);
    const btnName = props.btnName ? props.btnName : "Login";

    useEffect(() => {
        // store the jwtToken in the localStorage of user 
        //  once they log in and when on the web app check 
        // first if the user's jwtToken is present and setUser 
        // on the basis of that also
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
            }
        });
    }, [userName]);




    const handleAuthGoogle = () => {
        if (!userName) {
            auth
                .signInWithPopup(provider)
                .then(async (result) => {
                    setUser(result.user);
                    const token = await result.user.getIdToken();

                    const res = await fetch("https://elearning-monks.azurewebsites.net/auth/register", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            token: token
                        })
                    });

                    const data = await res.json();
                }).catch((error) => {
                    console.log(error.message);
                });
        }
    };

    const setUser = (user) => {
        dispatch(
            setUserLoginDetails({
                name: user.displayName,
                email: user.email,
                photo: user.photoURL
            })
        );
    };


    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Button
                onClick={handleOpen}
                className="button-default login-btn flex h-full items-center"
                variant="contained"
            >
                {btnName}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="login-popup"
                fullWidth
            >
                <DialogContent>
                    <div className="login w-full h-full flex flex-col gap-10 justify-center items-center">
                        <p className="text-3xl font-semibold text-primary">
                            Login & Learn
                        </p>
                        <img
                            className="w-full md:w-1/2 object-contain cursor-pointer signInWithGoogle"
                            onClick={handleAuthGoogle}
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQR4Rjost60tDsAWMDSld9Nja7eO8LMt8FQgatBKYyrs2-n1RBV3t2W3QbZO0FtbbHLFQ&usqp=CAU"
                            alt="Google Based Login"
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Login;
