// Global Imports 
import React from "react"
import { useState, useEffect } from "react";
import Countdown from "react-countdown";

// Other File Imports
import { auth } from "../../firebase";

// Design Imports 
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Swal from "sweetalert2";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Task = (props) => {
    const [popup, setPopup] = useState(true);
    const [confirm, setConfirm] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [userResponses, setUserResponses] = useState([
        {
            sno: 1,
            answer: "",
            score: -1
        }
    ]);
    const [submitted, setSubmitted] = useState(false);
    const moduleData = props.data;
    const questions = [].concat(moduleData.questions).sort((a, b) => (a.sno > b.sno ? 1 : -1));
    const moduleProgress = props.progress;
    const history = useHistory();

    useEffect(() => {
        if (moduleProgress.started !== true && moduleProgress.progress !== -1 && moduleProgress.progress !== 100 && moduleData.type === "task") {
            sendInteraction();
            setUserResponses(moduleProgress.responses);
        } else {
            if (moduleData.type == "task") {
                if (moduleProgress.started === true) setConfirm(true);
                if (moduleProgress.progress === 100 || moduleProgress.completed === true) setSubmitted(true);
                setUserResponses(moduleProgress.responses);
                setPopup(false);
            }
        }
    }, [props.courseSlug, props.moduleNo]);

    const sendInteraction = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            try {
                                const response = await fetch(`https://elearning-monks.azurewebsites.net/users/progress/task/interaction/${props.courseSlug}?moduleNo=${props.moduleNo}&type=task`, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken,
                                        progress: -1,
                                        questions: questions.length
                                    })
                                });
                                const selectedTaskData = await response.json();
                                if (selectedTaskData.apiStatus && selectedTaskData.apiStatus != "SUCCESS" && selectedTaskData.apiStatus != "Success" && selectedTaskData.apiStatus != "success") {
                                    history.push('/courses')
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }
    }

    const sendStarting = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            try {
                                const response = await fetch(`https://elearning-monks.azurewebsites.net/users/progress/task/start/${props.courseSlug}?moduleNo=${props.moduleNo}`, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken
                                    })
                                });
                                const selectedTaskData = await response.json();
                                if (selectedTaskData.apiStatus && selectedTaskData.apiStatus != "SUCCESS" && selectedTaskData.apiStatus != "Success" && selectedTaskData.apiStatus != "success") {
                                    history.push('/courses')
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }
    }


    const sendAnswers = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            try {
                                const response = await fetch(`https://elearning-monks.azurewebsites.net/users/progress/task/answers/${props.courseSlug}?moduleNo=${props.moduleNo}`, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken,
                                        answers: userResponses
                                    })
                                });
                                const selectedTaskData = await response.json();
                                if (selectedTaskData.apiStatus && selectedTaskData.apiStatus != "SUCCESS" && selectedTaskData.apiStatus != "Success" && selectedTaskData.apiStatus != "success") {
                                    history.push('/courses')
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }
    }


    const handleAccept = () => {
        setConfirm(true);
        setPopup(false);
        sendStarting();
    }

    const handleCancel = () => {
        setConfirm(false);
        setPopup(false);
    }

    const timer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            Swal.fire({
                icon: "info",
                title: "Times Up! Your Answers are submitting..."
            });
            handleSubmit();
        } else {
            // Render a countdown
            return (
                <Grid item xs={12} className="clock">
                    <h1>
                        {minutes}:{seconds}
                    </h1>
                </Grid>
            );
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    // Task Response Related
    const handleText = i => e => {
        let propertyName = e.target.name;
        let resp = [...userResponses];
        resp[i][propertyName] = e.target.value;
        setUserResponses(resp);
    };


    const handleSubmit = () => {
        sendAnswers();
        setConfirm(true);
        setSubmitted(true);
        Swal.fire({
            icon: "success",
            title: "Awesome! Your Answers are successfully Submitted",
        });
    }


    return (
        <>
            <div>
                <Dialog open={popup} className="task-men">
                    <DialogTitle id="alert-dialog-title">
                        Do you really want to proceed for the Task?
                    </DialogTitle>
                    <DialogContent>
                        Make sure you're in a quiet space. Goodluck!
                        <div style={{ height: "60px" }}></div>
                    </DialogContent>
                    <DialogActions style={{ padding: "25px" }}>
                        <Button
                            onClick={handleAccept}
                            style={{ marginRight: "20px" }}
                            color="primary"
                            variant="contained"
                        >
                            YES, PROCEED
                        </Button>
                        <Button
                            onClick={handleCancel}
                            color="secondary"
                            variant="contained"
                        >
                            CANCEL
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>

            {
                moduleProgress.started === false && moduleProgress.progress !== 100 && !confirm ?
                    <div
                        style={{
                            display: "flex",
                            height: "700px",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div style={{ width: "300px", height: "200px" }}>
                            <p style={{ float: "right" }}>
                                Do you want to Attempt now?
                            </p>
                            <br />
                            <p></p>
                            <br />
                            <Button
                                style={{ float: "right" }}
                                color="primary"
                                onClick={handleAccept}
                                variant="contained"
                            >
                                YES
                            </Button>
                        </div>
                    </div>
                    :
                    null

            }

            {
                confirm && !submitted ?
                    <>

                        <Grid container className="quiz">
                            <Grid item xs={12}>
                                <Countdown date={Date.now() + moduleProgress.timer * 1000} renderer={timer} />
                            </Grid>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {
                                    questions?.map((ques, i) => {
                                        return (
                                            <Step key={i}>
                                                <StepLabel>Question {ques.sno}</StepLabel>
                                                <StepContent>

                                                    <Grid container>
                                                        <Typography>{ques.question}</Typography>
                                                        {
                                                            ques.media !== "" && ques.media !== undefined && ques.media !== null ?
                                                                <img src={ques.media} alt={ques.question} /> : null
                                                        }
                                                        {
                                                            ques.info !== "" && ques.info !== undefined && ques.info !== null && ques.info.length !== 0 ?
                                                                <strong>
                                                                    <ul>
                                                                        {
                                                                            ques.info.map((info, k) => {
                                                                                return (
                                                                                    <li key={k}>
                                                                                        {info}
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </strong>
                                                                : null
                                                        }

                                                        {
                                                            ques.options !== "" && ques.options !== undefined && ques.options !== null && ques.options.length !== 0 ?
                                                                <Grid item xs={12} style={{ padding: "10px" }}>
                                                                    <FormControl component="fieldset">
                                                                        <RadioGroup
                                                                            name="answer"
                                                                            value={userResponses[i]?.answer}
                                                                            onChange={handleText(i)}
                                                                        >
                                                                            {
                                                                                ques.options?.map((option, j) => {
                                                                                    return (
                                                                                        <FormControlLabel
                                                                                            key={j}
                                                                                            value={option}
                                                                                            control={<Radio />}
                                                                                            label={option}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>

                                                                :
                                                                <Grid item xs={12} style={{ padding: "10px" }}>
                                                                    <FormControl component="fieldset">
                                                                        <TextField
                                                                            label="Enter your answer Here"
                                                                            variant="outlined"
                                                                            name="answer"
                                                                            value={userResponses[i]?.answer}
                                                                            onChange={handleText(i)}
                                                                            fullWidth
                                                                            multiline
                                                                            maxRows={5}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                        }


                                                    </Grid>


                                                    <div>
                                                        <div>
                                                            <Button disabled={activeStep === 0} onClick={handleBack}>
                                                                Back
                                                            </Button>
                                                            {activeStep === questions.length - 1 ? (
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleSubmit}
                                                                >
                                                                    Finish
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleNext}
                                                                >
                                                                    Next/Skip
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </StepContent>
                                            </Step>
                                        );
                                    })
                                }
                            </Stepper>
                        </Grid>
                    </>
                    :
                    confirm && submitted ?
                        <Grid container>
                            <Grid item xs={12} style={{ padding: "10px" }}>
                                <div align="center" className="qna">
                                    <div className="top-quiz-title">
                                        <h3>{moduleData.title}</h3>
                                        {
                                            moduleProgress.totalScores > 0 ?
                                                <h4>
                                                    Score : {moduleProgress.totalScores}
                                                </h4>
                                                :
                                                <h4>
                                                    Scores Awaited
                                                </h4>
                                        }
                                    </div>
                                    <div className="show-all">
                                        {
                                            questions?.map((data, i) => {
                                                return (
                                                    <div key={i} className="pb-6">
                                                        <p className="show-ques">
                                                            {data.question}
                                                        </p>
                                                        <div className="quiz-ans">
                                                            <b>Ans.&nbsp;&nbsp;&nbsp;</b>
                                                            {
                                                                userResponses[i]?.answer !== "" ? userResponses[i]?.answer : <span className="font-serif text-red-600">NOT ANSWERED</span>
                                                            }
                                                            {
                                                                userResponses[i]?.score >= 0 ?
                                                                    <div>
                                                                        <b>Score:&nbsp;&nbsp;&nbsp;
                                                                            {
                                                                                userResponses[i]?.score
                                                                            }
                                                                        </b>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    {
                                        moduleProgress.comment.length > 0 ?
                                            <div>
                                                Author Remarks : &nbsp;&nbsp;&nbsp;&nbsp;
                                                <strong>
                                                    {moduleProgress.comment}
                                                </strong>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        :
                        null
            }
        </>
    );
}

export default Task;