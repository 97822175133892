// Global Imports
import React from "react";
import "./Array.css";

const Description = (props) => {
    return (
        <>
            <div className="col-span-5 text-md ">
                <div>After the completion of this course the student will be able to:</div>

                <ul className="array">
                    {
                        props.desc?.map((line, i) => {
                            return (
                                <li key={i}>{line}</li>
                            );
                        })
                    }
                </ul>

            </div>
        </>

    );
}

export default Description;