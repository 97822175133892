// Global Imports
import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

// Design Imports
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Avatar, Button, IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";

// Other Files Imports
// import { auth } from "../../firebase";
// import {
// selectUserName,
// selectUserPhoto,
// setSignOutState,
// } from "../../providers/userSlice";

const Header = () => {
    // const dispatch = useDispatch();
    const [active, setActive] = useState(0);
    let history = useHistory();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [adminToken, setAdminToken] = useState(`JWT ${JSON.parse(localStorage.getItem('admin'))}`);
    // const userName = useSelector(selectUserName);
    // const userPhoto = useSelector(selectUserPhoto);
    var { path } = useRouteMatch();


    useEffect(() => {
        document.title="Admin Dashboard | Elearning - Recruiting Monk";
        if (path === "/" || path === "/admin/dashboard") {
            setActive(0);
        } else if (path === "/admin/courses") {
            setActive(1);
        } else if (path === "/admin/users") {
            setActive(2);
        } else if (path === "/admin/add/course") {
            setActive(3);
        }
    }, [path]);


    const handleAuth = () => {
        if (adminToken !== "") {
            localStorage.removeItem('admin');
            history.push('/home');
            setAdminToken("");
        }
    };

    return (
        <>

            
            <div
                style={{
                    position: "sticky",
                    top: "0px",
                    width: "100%",
                    backgroundColor: "#6495ED",
                    zIndex: "1000",
                }}
            >
                <div className="shadow-md">


                    {/* Navigation Bar for Large Screens  */}
                    <div className="hidden md:flex w-full p-2 px-6 flex-row items-center justify-between">
                        {/* <div className="flex flex-row items-center gap-4 text-lg font-bold"> */}
                        <Link to="/home" className="flex h-full items-center">
                            <img
                                alt="logo"
                                src="/images/logo.png"
                                className="h-12 object-contain cursor-pointer"
                            />
                        </Link>

                        <div className="flex justify-center">

                            {/* <Link to="/admin/dashboard" className="flex h-full items-center mx-7">
                                <p
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        lineHeight: "100%",
                                        textAlign: "center",
                                        borderBottom: active === 0 ? "2px solid #303F9F" : "",

                                    }}
                                    className={`cursor-pointer text-gray-100`}
                                >
                                    Dashboard
                                </p>
                            </Link> */}

                            <Link to="/admin/courses" className="flex h-full items-center mx-7">
                                <p
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        lineHeight: "100%",
                                        textAlign: "center",
                                        borderBottom: active === 1 ? "2px solid #303F9F" : "",
                                    }}
                                    className={`cursor-pointer text-gray-100`}
                                >
                                    Courses
                                </p>
                            </Link>

                            <Link
                                to="/admin/users"
                                className="flex h-full items-center mx-7"
                            >
                                <p
                                    style={{
                                        // position: "absolute",
                                        // left: "51.8%",
                                        fontStyle: "normal",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        lineHeight: "100%",
                                        textAlign: "center",
                                        borderBottom: active === 2 ? "2px solid #303F9F" : "",
                                    }}
                                    className={`cursor-pointer 
                                    text-gray-100
                                        `}
                                >
                                    Users
                                </p>
                            </Link>

{/* 
                            <Link
                                to="/admin/add/course"
                                className="flex h-full items-center mx-7"
                            >
                                <p
                                    style={{
                                        // position: "absolute",
                                        // left: "51.8%",
                                        fontStyle: "normal",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        lineHeight: "100%",
                                        textAlign: "center",
                                        borderBottom: active === 3 ? "2px solid #303F9F" : "",
                                    }}
                                    className={`cursor-pointer text-gray-100`}
                                >
                                    Add Course
                                </p>
                            </Link> */}

                        </div>

                        {adminToken === "" ? (
                            null
                        ) : (
                            <>
                                <div className="flex flex-row items-center gap-2">
                                    <p className="text-lg font-semibold text-primary">
                                        Hello Admin
                                    </p>
                                    <Avatar
                                        style={{ marginRight: "15px" }}
                                        alt="Admin"
                                        src={
                                            "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
                                        }
                                    />
                                    <Button
                                        onClick={handleAuth}
                                        className="button-default"
                                        variant="contained"
                                        color="primary"
                                    >
                                        <ExitToAppIcon />
                                        Logout
                                    </Button>
                                </div>
                            </>
                        )
                        }
                    </div>

                    {/* Navigation Bar for small screens */}
                    <div className="flex md:hidden w-full p-2 px-6 flex-row items-center justify-between">
                        <IconButton onClick={() => setOpenDrawer(true)}>
                            <Menu />
                        </IconButton>
                        <SwipeableDrawer
                            anchor={"left"}
                            open={openDrawer}
                            onClose={() => setOpenDrawer(false)}
                            onOpen={() => setOpenDrawer(true)}
                        >
                            <div role="presentation" className="w-40 flex flex-col items-center">
                                <List>
                                    {/* <ListItem button key={0}>
                                        <Link to="/admin/dashboard">
                                            <p className={`cursor-pointer ${active === 0 ? "text-primary border-b-2 border-gray-400" : "text-gray-600"}`}>
                                                Dashboard
                                            </p>
                                        </Link>
                                    </ListItem> */}
                                    <ListItem button key={1}>
                                        <Link to="/admin/courses">
                                            <p className={`cursor-pointer ${active === 1 ? "text-primary border-b-2 border-gray-400" : "text-gray-600"}`}>
                                                Courses
                                            </p>
                                        </Link>
                                    </ListItem>
                                    <ListItem button key={2}>
                                        <Link to="/admin/users">
                                            <p className={`cursor-pointer ${active === 2 ? "text-primary border-b-2 border-gray-400" : "text-gray-600"}`}>
                                                Users
                                            </p>
                                        </Link>
                                    </ListItem>
                                    {
                                        adminToken !== "" ? (
                                            <ListItem button key={3}>
                                                <Button onClick={handleAuth}
                                                    className="button-default"
                                                    variant="contained"
                                                    color="primary">
                                                    <ExitToAppIcon />
                                                    Logout
                                                </Button>
                                            </ListItem>
                                        ) : (null)
                                    }
                                </List>
                                <Divider />
                            </div>
                        </SwipeableDrawer>
                        <div className="flex flex-row items-center gap-4 text-lg font-semibold">
                            <Link to="/home">
                                <img
                                    alt="logo"
                                    src="/images/logo.png"
                                    className="h-12 object-contain cursor-pointer"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;