import React, { useState } from "react";
import styles from "./Events.module.css";
import ReactPlayer from "react-player";

const Events = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null); // State to track hovered card

  const events = [
    {
      img: "./images/events/event1.jpg",
      link: `https://www.linkedin.com/events/7181608689483591680/`,
      title: `“Building Hiring Plans for TA Leaders”`,
    },

    {
      img: "./images/events/event2.jpg",
      link: `https://www.linkedin.com/events/7119946460703428608/`,
      title: `“Most Common Sourcing Mistakes”`,
    },
    {
      img: "./images/events/event3.jpg",
      link: `
      https://www.linkedin.com/events/7132596362386280448/`,
      title: `“Sourcing Principles vs Hacks”`,
    },
    {
      img: "./images/events/event4.jpg",
      link: `https://www.linkedin.com/events/7080437021785165824/`,
      title: `“Why, When & How of X-ray Search?
  ”`,
    },
    {
      img: "./images/events/event5.jpg",
      link: `https://www.youtube.com/watch?v=C2lmm0DkQUs&t=2644s`,
      title: `“ChatGPT in TA
  ”`,
    },
  ];

  return (
    <div className={styles.events_container}>
      <div className={styles.events_subcontainer}>
        <div className={styles.events_heading}>
          Dive into the Wealth of Knowledge! 📖
        </div>

        <div className={styles.events_subheading}>
          Live Interactive Sessions
        </div>

        <div className={styles.events_info}>
        Ashfaq does LinkedIn live on some of the hottest topics in the TA space. So far he has done around 8 LinkedIn live sessions which garnered 100,000+ views & saw 2000+ live attendees.
        </div>

        <div className={styles.events_card_container}>
          {events.map((data, index) => (
            <a
              className={`${styles.events_card} ${
                index === 1 || index === 2 || index === 3
                  ? styles.eventCardsMiddle
                  : ""
              }`}
              style={{ width: `${index === 0 || index === 4 ? "100%" : ""}` }}
              key={index}
              onMouseEnter={() => setHoveredIndex(index)} // Set hovered index
              onMouseLeave={() => setHoveredIndex(null)} // Reset hovered index
              href={data.link}
              target="_blank"
            >
              <div
                className={`${styles.events_card_heading} ${
                  hoveredIndex === index ? styles.full_heading : ""
                }`}
              >
                {data.title}
              </div>
              <div className={styles.events_card_video}>
                <div className="aspect-ratio-box">
                  <img src={data.img} alt="" width="100%" />
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Events;
