import React, { useState } from "react";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ImageUpload from "./ImageUpload";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const RegisterCourse = (props) => {
    const history = useHistory();
    const { handleNext, authorsList, registerCourseForm, setRegisterCourseForm } = props;

    const handleInput = (e) => {
        const { name, value } = e.target;
        setRegisterCourseForm((prev) => {
            return ({
                ...prev,
                [name]: value
            })
        })
    }

    const submitRegisterCourse = async () => {
        var errors = "";
        var count = 0;
        if (registerCourseForm.title === "") {
            if (count > 0) errors += ", ";
            errors += "Course Name";
            count++;
        }

        if (registerCourseForm.author === "") {
            if (count > 0) errors += ", ";
            errors += "Course Author Name";
            count++;
        }

        if (registerCourseForm.previewTxt === "") {
            if (count > 0) errors += ", ";
            errors += "Course Preview Text";
            count++;
        }

        if (registerCourseForm.price === "") {
            if (count > 0) errors += ", ";
            errors += "Course Price";
            count++;
        }

        if (registerCourseForm.duration === "") {
            if (count > 0) errors += ", ";
            errors += "Course Duration";
            count++;
        }

        if (registerCourseForm.previewImageUrl === "") {
            if (count > 0) errors += ", ";
            errors += "Course Preview Image";
            count++;
        }


        if (errors !== "") {
            errors = "Following Errors occured when you proceeded : " + errors + " is/are required.";
            Swal.fire({
                icon: "error",
                title: 'Oops...',
                text: errors
            })
        } else {
            handleNext();


            // if (localStorage.getItem('admin')) {
            //     const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            //     try {
            //         const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/courses/`, {
            //             method: "POST",
            //             headers: {
            //                 Accept: "application/json",
            //                 "Content-Type": "application/json",
            //                 "Authorization": adminToken
            //             },
            //             body:JSON.stringify({
            //                 title:registerCourseForm.title,
            //                 author:registerCourseForm.author,
            //                 previewTxt:registerCourseForm.previewTxt,
            //                 price:registerCourseForm.price,
            //                 author:registerCourseForm.duration,
            //                 previewImageUrl:registerCourseForm.previewImageUrl
            //             })
            //         });
            //         const courseRegister = await response.json();
            //         if (courseRegister?.apiStatus && courseRegister.apiStatus !== "SUCCESS" && courseRegister.apiStatus !== "success" && courseRegister.apiStatus !== "Success") {
            //             Swal.fire({
            //                 icon:'error',
            //                 title: 'Failure',
            //                 text: courseRegister?.errorMessage
            //             })
            //         } else {
            //             handleNext();
            //         }
            //     } catch (error) {
            //         console.log("Error in Post Course ", error);
            //         history.push(`/home`);
            //     }
            // } else {
            //     history.push(`/home`);
            // }

        }


    }

    return (
        <>
            <form className="w-full p-6">
                <Grid container className="w-full">
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <TextField
                            label="Enter the Course Name"
                            variant="outlined"
                            name="title"
                            value={registerCourseForm.title}
                            fullWidth
                            onChange={handleInput}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={authorsList.map((option) => option?.name)}
                            renderInput={(params) =>
                                <TextField
                                    variant="outlined"
                                    {...params} label="Enter the Author Name"
                                    name="author"
                                    fullWidth
                                    value={registerCourseForm.author}
                                    onChange={handleInput}
                                />
                            }
                            // value={registerCourseForm.author}
                            onChange={(e, newValue) => setRegisterCourseForm((prev) => { return ({ ...prev, author: newValue }) })}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ padding: "10px" }}>
                        <TextField
                            label="Enter Course Preview Text for Course Cards"
                            variant="outlined"
                            name="previewTxt"
                            value={registerCourseForm.previewTxt}
                            fullWidth
                            multiline
                            maxRows={5}
                            autoComplete="off"
                            onChange={handleInput}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <TextField
                            label="Enter the Course Price"
                            variant="outlined"
                            name="price"
                            value={registerCourseForm.price}
                            fullWidth
                            autoComplete="off"
                            onChange={handleInput}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <TextField
                            label="Enter the Course Duration like One month, Two weeks, 60 hrs"
                            variant="outlined"
                            name="duration"
                            value={registerCourseForm.duration}
                            fullWidth
                            autoComplete="off"
                            onChange={handleInput}
                        />
                    </Grid>

                    <ImageUpload
                        label="Course Preview Image"
                        name="previewImageUrl"
                        id="course-image"
                        data={registerCourseForm}
                        setData={setRegisterCourseForm}
                    />
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                        onClick={submitRegisterCourse}
                        className="button-default"
                        variant="contained"
                        color="primary"
                    >
                        Next
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default RegisterCourse;
