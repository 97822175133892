import React from "react";
import styles from "./BuyMessage.module.css";

const BuyMessage = () => {
  return (
    <div className={styles.buy_message_container}>
      <div className={styles.buy_message_semi_container}>
        <p className={styles.buy_message}>Like what you see ?</p>
        <button
          onClick={() => window.open("https://wa.me/+917416266999", "_blank")}
          className={styles.join_button_option}
          style={{
            // border: "1.5px solid #191919",
          }}
        >
          Join Mini Diploma
        </button>
      </div>
    </div>
  );
};

export default BuyMessage;
