import React from "react";
import styles from "./Info.module.css";

const Info = () => {
  return (
    <div className={styles.info_container}>
      <div className={styles.info_sub_container}>
        <div className={styles.info_heading}>What's Peculiar about Mini Diploma?</div>
        <div className={styles.info_text}>
          We bring in the most elusive content globally, by leveraging our own
          research work around bigdata concepts in sourcing & persona first
          hiring.
        </div>
      </div>
    </div>
  );
};

export default Info;
