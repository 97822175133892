// Global Imports 
import React from "react"

// Other File Imports

// Design Imports 


const Interaction = (props) => {
    return (
        <>
            <div className="h-96 animate-pulse bg-gray-200" style={{ width: "36rem" }}>
            </div>
        </>
    );
}

export default Interaction;