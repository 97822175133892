import React from "react";

const LandingFooter = () => {
  return (
    <section className="footer wf-section">
      <div className="padding-global padding-section-small">
        <div className="container-medium">
          <div className="footer_wrapper">
            <h2 className="heading-31 text-red">RecruitingMonk Academy</h2>
            <div className="link_wrapper">
              <a
                href="mailto:yaswanth@recruitingmonk.com"
                className="text-style-link w-inline-block"
              >
                <p className="no-wrap">yaswanth@recruitingmonk.com</p>
              </a>
            </div>
            <div className="copyright-text">
              Copyright © 2023 RecruitingMonk Academy. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingFooter;
