// Global Imports
import React from "react";

const Info = (props) => {
    return (
        <>
            <div className="font-medium mb-3 md:gap-2 flex-col md:flex-row md:items-center">
                Mode:
                <p className="font-normal">
                    Virtual (Learning + Hands on + Mentorship)
                </p>
                Requirement:
                <p className="font-normal">
                    Candidate should have laptop + wifi + mobile
                </p>
                Mentorship:
                <p className="font-normal">
                    Get mentored by top Recruiters from Facebook, Google, Uber & others
                </p>
            </div>
        </>

    );
}

export default Info;