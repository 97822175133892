// Global Imports
import React from "react";

// design imports
import "./Array.css";

const Skills = (props) => {
    return (
        <>
            <div
                className="grid grid-cols-4 gap-4 w-full  my-10 items-center"
            >
                <p
                    className="col-span-4 font-medium text-2xl mb-4"
                    style={{ color: "#ad2831", fontWeight: "bold" }}
                >
                    Skills You will learn:
                </p>
                <div className="col-span-5 text-md ">
                    <ul className="array">
                        {
                            props.skills?.map((line, i) => {
                                return (
                                    <li key={i}>{line}</li>
                                );
                            })
                        }
                    </ul>

                </div>

            </div>
        </>

    );
}

export default Skills;