import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";


import RegisterCourse from './RegisterCourse';
import CoursePreview from './CoursePreview';




import { Box, Button, Grid, TextField } from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
// import { Autocomplete } from "@material-ui/lab";
// import ImageUpload from "./ImageUpload";
// import Swal from "sweetalert2";

const Steps = () => {
    const steps = [
        "Register Course Name",
        "Add Course Preview",
        "Add Student Testimonials",
        "Add Student Videos",
        "Add Student Success Stories",
        "Add URL or Add Curriculum",
        "Go Online!"
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [authorsList, setAuthorsList] = useState([]);
    const [registerCourseForm, setRegisterCourseForm] = useState({
        title: "",
        author: "" || null,
        previewTxt: "",
        price: "",
        duration: "",
        previewImageUrl: ""
    });
    const history = useHistory();

    useEffect(() => {
        getAuthorsList();
    }, []);

    const getAuthorsList = async () => {
        const response = await fetch(`http://localhost:5000/author/get/list`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        });
        const authorData = (await response.json());
        setAuthorsList(authorData?.data);
    }

    // const handleInput = (e) => {
    //     const { name, value } = e.target;
    //     setRegisterCourseForm((prev) => {
    //         return ({
    //             ...prev,
    //             [name]: value
    //         })
    //     })
    // }

    // const submitRegisterCourse = async () => {
    //     var errors = "";
    //     var count = 0;
    //     if (registerCourseForm.title === "") {
    //         if (count > 0) errors += ", ";
    //         errors += "Course Name";
    //         count++;
    //     }

    //     if (registerCourseForm.author === "") {
    //         if (count > 0) errors += ", ";
    //         errors += "Course Author Name";
    //         count++;
    //     }

    //     if (registerCourseForm.previewTxt === "") {
    //         if (count > 0) errors += ", ";
    //         errors += "Course Preview Text";
    //         count++;
    //     }

    //     if (registerCourseForm.price === "" || registerCourseForm.price < 0) {
    //         if (count > 0) errors += ", ";
    //         errors += "Course Price";
    //         count++;
    //     }

    //     if (registerCourseForm.duration === "") {
    //         if (count > 0) errors += ", ";
    //         errors += "Course Duration";
    //         count++;
    //     }

    //     if (registerCourseForm.previewImageUrl === "") {
    //         if (count > 0) errors += ", ";
    //         errors += "Course Preview Image";
    //         count++;
    //     }


    //     if (errors !== "") {
    //         errors = "Following Errors occured when you proceeded : " + errors + " is/are required.";
    //         Swal.fire({
    //             icon: "error",
    //             title: 'Oops...',
    //             text: errors
    //         })
    //     } else {
    //         handleNext();


    //         // if (localStorage.getItem('admin')) {
    //         //     const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
    //         //     try {
    //         //         const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/courses/`, {
    //         //             method: "POST",
    //         //             headers: {
    //         //                 Accept: "application/json",
    //         //                 "Content-Type": "application/json",
    //         //                 "Authorization": adminToken
    //         //             },
    //         //             body:JSON.stringify({
    //         //                 title:registerCourseForm.title,
    //         //                 author:registerCourseForm.author,
    //         //                 previewTxt:registerCourseForm.previewTxt,
    //         //                 price:registerCourseForm.price,
    //         //                 author:registerCourseForm.duration,
    //         //                 previewImageUrl:registerCourseForm.previewImageUrl
    //         //             })
    //         //         });
    //         //         const courseRegister = await response.json();
    //         //         if (courseRegister?.apiStatus && courseRegister.apiStatus !== "SUCCESS" && courseRegister.apiStatus !== "success" && courseRegister.apiStatus !== "Success") {
    //         //             Swal.fire({
    //         //                 icon:'error',
    //         //                 title: 'Failure',
    //         //                 text: courseRegister?.errorMessage
    //         //             })
    //         //         } else {
    //         //             handleNext();
    //         //         }
    //         //     } catch (error) {
    //         //         console.log("Error in Post Course ", error);
    //         //         history.push(`/home`);
    //         //     }
    //         // } else {
    //         //     history.push(`/home`);
    //         // }

    //     }


    // }



    const isStepOptional = (step) => {
        return step === 2 || step === 3 || step === 4;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };















    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}

            </Stepper>




            {/* Footer of Each step  */}
            {activeStep === steps.length ? (
                <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </>
            ) : (
                <>
                    <Box sx={{ mt: 1, mb: 1 }}>





















                        {/* ---------------------------------- Register Course ----------------------------   */}
                        {
                            activeStep === 0 && <RegisterCourse handleNext={handleNext} registerCourseForm={registerCourseForm} setRegisterCourseForm={setRegisterCourseForm} authorsList={authorsList} />
                        }





















                        {/* -------------------------------- Course Preview ---------------------------------- */}
                        {
                            activeStep === 1 && <CoursePreview handleBack={handleBack} handleNext={handleNext} />
                        }
                    </Box>
                    {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}

                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box> */}
                </>
            )}
        </Box>
    );
}

export default Steps;
