// Global Imports 
import React from "react";

const PreviewCardLoading = () => {

    return (
        <>
            <div className="my-4 p-2 grid grid-cols-5 w-full gap-2 mb-12 pb-6 border-b-2 border-gray-400">
                <div className="col-span-5 flex flex-col md:flex-row md:items-center gap-2 justify-between">
                    <div className="h-6 w-48 animate-pulse bg-red-400">
                    </div>
                </div>
                <div className="col-span-5 h-6 w-full animate-pulse bg-gray-300"></div>
                <div className="col-span-5 h-6 w-1/2 animate-pulse bg-gray-300"></div>
                <div className="col-span-5 gap-4 flex flex-row items-center">
                    <div className="md:gap-2 flex flex-col md:flex-row md:items-center animate-pulse h-6 bg-gray-500 w-28">
                    </div>
                    <div className="md:gap-2 flex flex-col md:flex-row md:items-center h-6 animate-pulse bg-gray-300 w-1/6">
                    </div>
                </div>
                <div className="flex h-6 w-28 animate-pulse bg-blue-400 mr-2">
                </div>
                <div className="flex h-6 w-28 animate-pulse bg-blue-400 mr-2">
                </div>
                <div className="md:flex block h-6 w-24 animate-pulse bg-red-400 md:ml-96">
                </div>
            </div>
        </>
    );
}

export default PreviewCardLoading;