// Global Imports
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

// Design Imports
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Avatar, Button, IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";

// Other Files Imports
import { auth } from "../../firebase";
import {
    selectUserName,
    selectUserPhoto,
    setSignOutState,
} from "../../Providers/userSlice";
import Login from "./Login";

const scrollToRef = (ref) => window.scrollTo(0, 0);

const Header = () => {
    const dispatch = useDispatch();
    const [active, setActive] = useState(0);
    let history = useHistory();
    const [openDrawer, setOpenDrawer] = useState(false);
    const userName = useSelector(selectUserName);
    const userPhoto = useSelector(selectUserPhoto);
    var { path } = useRouteMatch();
    const topRef = useRef(null);
    const executeScroll = () => scrollToRef(topRef);


    useEffect(() => {
        if (path === "/" || path === "/home") {
            setActive(0);
        } else if (path === "/courses") {
            setActive(1);
        } else if (path === "https://forms.gle/TD7jjAqNEb8grTan7") {
            setActive(2);
        } else if (path === "/learning") {
            setActive(3);
        }
    }, [path]);

    const handleAuth = () => {

        if (userName) {
            auth
                .signOut()
                .then(() => {
                    dispatch(setSignOutState());
                    history.push("/");
                })
                .catch((err) => alert(err.message));
        }
    };

    return (
        <>
            <div
                style={{
                    position: "sticky",
                    top: "0px",
                    width: "100%",
                    backgroundColor: "white",
                    zIndex: "1000",
                }}
            >
                <div className="shadow-md">


                    {/* Navigation Bar for Large Screens  */}
                    <div className="hidden md:flex w-full p-2 px-6 flex-row items-center justify-between">
                        {/* <div className="flex flex-row items-center gap-4 text-lg font-bold"> */}
                        <Link to="/home" className="flex h-full items-center" onClick={executeScroll}>
                            <img
                                alt="logo"
                                src="/images/logo.png"
                                className="h-12 object-contain cursor-pointer"
                            />
                        </Link>

                        <div className="flex justify-center">

                            <Link to="/home" className="flex h-full items-center mx-7" onClick={executeScroll}>
                                <p
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        lineHeight: "100%",
                                        textAlign: "center",
                                        borderBottom: active === 0 ? "2px solid #AD2831" : "",

                                    }}
                                    className={`cursor-pointer text-gray-600`}
                                >
                                    Home
                                </p>
                            </Link>

                            <Link to="/courses" className="flex h-full items-center mx-7" onClick={executeScroll}>
                                <p
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        lineHeight: "100%",
                                        textAlign: "center",
                                        borderBottom: active === 1 ? "2px solid #AD2831" : "",
                                    }}
                                    className={`cursor-pointer text-gray-600`}
                                >
                                    Courses
                                </p>
                            </Link>

                            <Link
                                to={{ pathname: "https://forms.gle/TD7jjAqNEb8grTan7" }}
                                target="_blank"
                                className="flex h-full items-center mx-7"
                                onClick={executeScroll}
                            >
                                <p
                                    style={{
                                        // position: "absolute",
                                        // left: "51.8%",
                                        fontStyle: "normal",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        lineHeight: "100%",
                                        textAlign: "center",
                                        borderBottom: active === 2 ? "2px solid #AD2831" : "",
                                    }}
                                    className={`cursor-pointer 
                                    text-gray-600
                                        `}
                                >
                                    Support
                                </p>
                            </Link>

                            {
                                userName && (
                                    <Link to="/learning" className="flex h-full items-center mx-7" onClick={executeScroll}>
                                        <p
                                            style={{
                                                fontStyle: "normal",
                                                fontWeight: "bold",
                                                fontSize: "20px",
                                                lineHeight: "100%",
                                                textAlign: "center",
                                                borderBottom: active === 3 ? "2px solid #AD2831" : "",
                                            }}
                                            className={`cursor-pointer text-gray-600`}
                                        >
                                            My Learning
                                        </p>
                                    </Link>

                                )
                            }


                        </div>

                        {!userName ? (
                            <Login />
                        ) : (
                            <>
                                <div className="flex flex-row items-center gap-2">
                                    <p className="text-lg font-semibold text-primary">
                                        Hello,
                                        {userName.split(" ")[0]}
                                    </p>
                                    <Avatar
                                        style={{ marginRight: "15px" }}
                                        alt={userName}
                                        src={
                                            userPhoto ||
                                            "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
                                        }
                                    />
                                    <Button
                                        onClick={handleAuth}
                                        className="button-default"
                                        variant="contained"
                                        color="primary"
                                    >
                                        <ExitToAppIcon />
                                        Logout
                                    </Button>
                                </div>
                            </>
                        )
                        }
                    </div>

                    {/* Navigation Bar for small screens */}
                    <div className="flex md:hidden w-full p-2 px-6 flex-row items-center justify-between">
                        <IconButton onClick={() => setOpenDrawer(true)}>
                            <Menu />
                        </IconButton>
                        <SwipeableDrawer
                            anchor={"left"}
                            open={openDrawer}
                            onClose={() => setOpenDrawer(false)}
                            onOpen={() => setOpenDrawer(true)}
                        >
                            <div role="presentation" className="w-40 flex flex-col items-center">
                                <List>
                                    <ListItem button key={0}>
                                        <Link to="/home" onClick={executeScroll}>
                                            <p className={`cursor-pointer ${active === 0 ? "text-primary border-b-2 border-gray-400" : "text-gray-600"}`}>
                                                eLearn
                                            </p>
                                        </Link>
                                    </ListItem>
                                    <ListItem button key={1}>
                                        <Link to="/courses" onClick={executeScroll}>
                                            <p className={`cursor-pointer ${active === 1 ? "text-primary border-b-2 border-gray-400" : "text-gray-600"}`}>
                                                Courses
                                            </p>
                                        </Link>
                                    </ListItem>
                                    <ListItem button key={2}>
                                        <Link to={{ pathname: "https://forms.gle/TD7jjAqNEb8grTan7" }} target="_blank" onClick={executeScroll}>
                                            <p className={`cursor-pointer ${active === 2 ? "text-primary border-b-2 border-gray-400" : "text-gray-600"}`}>
                                                Support
                                            </p>
                                        </Link>
                                    </ListItem>
                                    {
                                        userName && (
                                            <ListItem button key={3}>
                                                <Link to="/learning" onClick={executeScroll}>
                                                    <p className={`cursor-pointer ${active === 3 ? "text-primary border-b-2 border-gray-400" : "text-gray-600"}`}>
                                                        My Learning
                                                    </p>
                                                </Link>
                                            </ListItem>
                                        )
                                    }
                                    {
                                        userName ? (
                                            <ListItem button key={4}>
                                                <Button onClick={handleAuth}
                                                    className="button-default"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    <ExitToAppIcon />
                                                    Logout
                                                </Button>
                                            </ListItem>
                                        ) : (null)
                                    }
                                </List>
                                <Divider />
                            </div>
                        </SwipeableDrawer>
                        <div className="flex flex-row items-center gap-4 text-lg font-semibold">
                            <Link to="/home">
                                <img
                                    alt="logo"
                                    src="/images/logo.png"
                                    className="h-12 object-contain cursor-pointer"
                                />
                            </Link>
                        </div>

                        {!userName ? (
                            <Login />
                        ) : (null)
                        }

                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;