import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import Checker from "./Checker";
import Phone from "./custom/Phone"

export const COURSESLIST = [
    {
        Header: 'Course Name',
        Footer: 'Course Name',
        accessor: 'title'
    },
    {
        Header: 'Viewed By',
        Footer: 'Viewed By',
        accessor: 'viewedBy',
    },
    {
        Header: 'Taken By',
        Footer: 'Taken By',
        accessor: 'takenBy'
    },
    {
        Header: 'Status',
        Footer: 'Status',
        accessor: 'online',
        Cell: ({ value }) => {
            return value ?
                <>
                    <span className="text-green-700">
                        Online
                    </span>
                </>
                :
                <>
                    <span className="text-red-700">
                        Offline
                    </span>
                </>
        }       
    }
];

export const CURRICULUMLIST = [
    {
        Header: 'Module No',
        Footer: 'Module No',
        accessor: 'moduleNo',
    },
    {
        Header: 'Module Title',
        Footer: 'Module Title',
        accessor: 'title'
    },
    {
        Header: 'Type',
        Footer: 'Type',
        accessor: 'type'
    }
];

export const DOUBTLIST = [
    {
        Header: 'Raised On',
        Footer: 'Raised On',
        accessor: 'createdAt',
        Cell: ({ value }) => {
            return format(new Date(value), 'dd/MM/yyyy hh:mm:ss')
        }
    },
    {
        Header: 'Name',
        Footer: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Doubt',
        Footer: 'Doubt',
        accessor: 'doubtTxt'
    },
    {
        Header: 'Answer',
        Footer: 'Answer',
        accessor: '_id',
        Cell: ({ value }) => {
            return <Button variant="contained" color="primary" className="button-default"><Link to={`/admin/doubt/${value}`} target="_blank">Answers</Link></Button>
        }
    }
];



export const TESTLIST = [
    {
        Header: 'Attempted On',
        Footer: 'Attempted On',
        accessor: 'updatedAt',
        Cell: ({ value }) => {
            return format(new Date(value), 'dd/MM/yyyy hh:mm:ss')
        }
    },
    {
        Header: 'Name',
        Footer: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Answers',
        Footer: 'Answers',
        Cell: (data) => {
            return (
                <Checker data={data.row.original} />
            )
        }
    },
    {
        Header: 'Score',
        Footer: 'Score',
        accessor: 'totalScores',
        Cell: ({ value }) => {
            return value < 0 ? "TBD*" : value
        }
    }
];

export const USERSLIST = [
    {
        Header: 'Name',
        Footer: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Contact',
        Footer: 'Contact',
        Cell: (data) => {
            return (
                <Phone data={data.row.original} />
            )
        }
    },
    {
        Header: 'Status',
        Footer: 'Status',
        accessor: 'courseTaken',
        Cell: ({ value }) => {
            return value.length > 0 ?
                <>
                    <span className="text-green-700">
                        Active
                    </span>
                </>
                :
                <>
                    <span className="text-red-700">
                        Inactive
                    </span>
                </>
        }
    },
    {
        Header: 'Details',
        Footer: 'Details',
        accessor: '_id',
        Cell: ({ value }) => {
            return <Button variant="contained" color="primary" className="button-default"><Link to={`/admin/user/${value}`} target="_blank">Know More</Link></Button>
        }
    }
];


