import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import VideosGrid from "./VideosGrid";

import { CircularProgress } from "@material-ui/core";
import { firebaseAnalytics } from "../../firebase";

const VideosSection = (props) => {
    const [courseStudentVideoData, setCourseStudentVideoData] = useState([]);
    const [courseStudentVideoDataLoading, setCourseStudentVideoDataLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getCourseStudentVideoData();
    }, [])

    const getCourseStudentVideoData = async () => {
        try {
            setCourseStudentVideoDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/students/${props.courseSlug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const selectedCourseData = (await response.json());
            console.log(selectedCourseData);
            if (selectedCourseData.apiStatus && selectedCourseData.apiStatus != "SUCCESS" && selectedCourseData.apiStatus != "Success" && selectedCourseData.apiStatus != "success") {
                history.push('/courses');
            }
            setCourseStudentVideoData(selectedCourseData.data);
            setCourseStudentVideoDataLoading(false);
            firebaseAnalytics.logEvent(`${props?.courseSlug}_student_videos_visited`);
        } catch (error) {
            console.log("Courses Student Videos cannot be fetched.", error);
        }
    }


    return (
        <>
            {
                courseStudentVideoDataLoading ?
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
                    :
                    courseStudentVideoData?.length > 0 ?
                        <VideosGrid data={courseStudentVideoData} />
                        :
                        null
            }
        </>
    );
}

export default VideosSection;