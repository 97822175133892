import React, { useState } from "react";
import styles from "../mini_diploma_component/FAQ.module.css";

const AboutCourse = () => {
  const [open, setOpen] = useState(null);

  const questions = [
    {
      question: "1. Prompting Foundations:",
      asnwer: `a. Difference between Prompting vs Prompting Engineering (PE)
b. What are the the prompting frameworks?
c. Use Case outline: Prompting & Prompt Engineering (PE)`,
    },
    {
      question: "2. Prompting Use Cases",
      asnwer: `a. Direct Prompts (Calculate X, Spell Check etc..)
b. Brain Storming Prompts (Broadens horizon of thoughts)
c. Task 1: Create Prompts for solving basic TA use cases`,
    },
    {
      question: "3. Prompt Engineering (PE) Use Cases",
      asnwer: `a. Importance of DOMAIN expertise
b. Mind-set of a Problem Solver
c. Foundations of Engineering a Prompt
d. Prompt Orchestration
e. Task 2: Think of use cases, where you can implement prompt orchestration`,
    },
    {
      question: "4. Case Study 1: Sourcing",
      asnwer: `a. Breaking down the Sourcing Problem
b. Spotting areas to Engineer Prompts
c. Validating Problem Statement in ref to cost, time and efficacy
d. Engineer Prompt
e. Task 3: Build a PE in Sourcing & reason out, how it saves time, cost or improves efficacy.`,
    },
    {
      question: "5. Case Study 2: Resume Parsing & Scoring",
      asnwer: `a. Choice of model 40 mini vs 40: In ref to Cost vs Speed vs Efficacy Analysis
b. Pattern analysis of Resumes
c. Implementing Prompt Orchestration for Parsing, Scoring and Ranking
d. Task 4: Build a robust solution to Parse & Score resumes`,
    },
    {
      question: "6. Group Activity",
      asnwer: ``,
    },
    {
      question: "7. Case Study 3: How to save $$$ in interviewing?",
      asnwer: `a. Role PERSONA understanding
b. Understanding skills, KRAS, KPIs via prompting
c. Generating right probing Qs for fitment analysis
d. Constantly evaluate first round reject ratio`,
    },
    {
      question: "8. Case Study 4: Analytics & Insights",
      asnwer: `a. Data Analysis & Data Mining foundations
b. How to weed out right talent insights`,
    },
    {
      question: "9. Workflow Automation",
      asnwer: `a. How to automate workflows
b. Handpicking key Al tools & its area of improvisation in TA.
c. How to implement your prompts & PE in your worflows`,
    },
    {
      question: "10. Final: Submit a Research Paper (Improving hiring efficacy via GAI)",
      asnwer: ``,
    },
  ];

  const handleShowAnswer = (key) => {
    setOpen((prevOpen) => (prevOpen === key ? null : key));
  };

  const renderAnswers = (asnwer) => {
    return (
      <ul style={{ paddingLeft: "20px" }}>
        {asnwer
          .split("\n")
          .filter((point) => point.trim() !== "")
          .map((point, idx) => (
            <li key={idx} style={{ marginBottom: "5px" }}>
              {point.trim()}
            </li>
          ))}
      </ul>
    );
  };

  return (
    <div className="w-full py-20 px-2 md:px-40 items-center justify-center">
      <div className="flex flex-col items-center md:items-start">
        <h3>What does the course look like?</h3>

        {/* Month 1 */}
        <h4 className="mt-8 mb-3 text-center md:text-left px-2 text-gray-400 w-full">Month 1</h4>
        <div className={styles.faq_content} style={{ padding: "1rem 0" }}>
          {questions.slice(0, 4).map((question, key) => (
            <div
              className="rounded-full px-12 pl-16 py-6 flex items-center justify-between w-full"
              style={{ border: "2px solid rgba(0, 0, 0, 0.07)" }}
              onClick={() => handleShowAnswer(key)}
              key={key}
            >
              <div className={styles.question_side}>
                <div className="text-xl font-semibold">{question.question}</div>
                {open === key && question.asnwer && (
                  <div className={`${styles.faq_answer}`} style={{ marginTop: "10px" }}>
                    {renderAnswers(question.asnwer)}
                  </div>
                )}
              </div>
              <div className={styles.dropdown}>
                <img
                  src="/images/dropdown.svg"
                  alt=""
                  style={{ width: "15px" }}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Month 2 */}
        <h4 className="mt-8 mb-3 px-2 text-center md:text-left text-gray-400 w-full">Month 2</h4>
        <div className={styles.faq_content} style={{ padding: "1rem 0" }}>
          {questions.slice(4, 7).map((question, key) => (
            <div
              className="rounded-full px-12 pl-16 py-6 flex items-center justify-between w-full"
              style={{ border: "2px solid rgba(0, 0, 0, 0.07)" }}
              onClick={() => handleShowAnswer(key + 4)}
              key={key + 4}
            >
              <div className={styles.question_side}>
                <div className="text-xl font-semibold">{question.question}</div>
                {open === key + 4 && question.asnwer && (
                  <div className={`${styles.faq_answer}`} style={{ marginTop: "10px" }}>
                    {renderAnswers(question.asnwer)}
                  </div>
                )}
              </div>
              <div className={styles.dropdown}>
                <img
                  src="/images/dropdown.svg"
                  alt=""
                  style={{ width: "15px" }}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Month 3 */}
        <h4 className="mt-8 mb-3 px-2 text-center md:text-left text-gray-400 w-full">Month 3</h4>
        <div className={styles.faq_content} style={{ padding: "1rem 0" }}>
          {questions.slice(7, 10).map((question, key) => (
            <div
              className="rounded-full px-12 py-6 pl-16 flex items-center justify-between w-full"
              style={{ border: "2px solid rgba(0, 0, 0, 0.07)" }}
              onClick={() => handleShowAnswer(key + 6)}
              key={key + 6}
            >
              <div className={styles.question_side}>
                <div className="text-xl font-semibold">{question.question}</div>
                {open === key + 6 && question.asnwer && (
                  <div className={`${styles.faq_answer}`} style={{ marginTop: "10px" }}>
                    {renderAnswers(question.asnwer)}
                  </div>
                )}
              </div>
              <div className={styles.dropdown}>
                <img
                  src="/images/dropdown.svg"
                  alt=""
                  style={{ width: "15px" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutCourse;
