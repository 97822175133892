import React from "react";

const Progress = () => {
    return (
        <>
            <div>
                <div className="h-6 w-1/2 animate-pulse bg-gray-700 mb-2"></div>
                <div className="h-1 w-full animate-pulse bg-red-300 mb-6"></div>
            </div>
        </>
    );
}

export default Progress;


