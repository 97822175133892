import React, { useEffect } from "react";
import "./appinfo.css";
import Header from "../../component/global/Header";
import Footer from "../../component/global/Footer";
import { firebaseAnalytics } from "../../firebase";

const Refund = () => {
    useEffect(() => {
        document.title="Refund Policy | Elearning - Recruiting Monk";
        firebaseAnalytics.logEvent("refund_policy_visited");
    });
    return (
        <>
            <Header />
            <div className="appinfo-body">
                <div className="appinfo-main shadow-inner">
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "16pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Refund Policy
                        </span>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Thank you for your purchase. We hope you are happy with your purchase.
                            However, if you are not completely satisfied with your purchase for any
                            reason, you may ask for a full refund within 7 days of the purchase
                            date.&nbsp;
                        </span>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            <br />
                        </span>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            REFUND PROCESS
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            To raise refund request contact our officer :
                        </span>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            <br />
                        </span>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Mr. Yashwant Lohar&nbsp;
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.7999999999999998",
                            backgroundColor: "#ffffff",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                            padding: "11pt 0pt 0pt 0pt"
                        }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Email:&nbsp;
                        </span>
                        <a
                            href="mailto:Yaswanth@recruitingmonk.com"
                            style={{ textDecoration: "none" }}
                        >
                            <span
                                style={{
                                    fontSize: "12pt",
                                    fontFamily: "Arial",
                                    color: "#1155cc",
                                    backgroundColor: "#ffffff",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap"
                                }}
                            >
                                Yaswanth@recruitingmonk.com
                            </span>
                            <span
                                style={{
                                    fontSize: "12pt",
                                    fontFamily: "Arial",
                                    color: "#000000",
                                    backgroundColor: "#ffffff",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap"
                                }}
                            >
                                <br />
                            </span>
                        </a>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Phone: +91 74162 66999&nbsp;
                        </span>
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Refund;