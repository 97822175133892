// Global Imports 
import React, { useRef } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    selectUserName,
} from "../../Providers/userSlice";


// Design Imports 
import { CheckCircle } from "@material-ui/icons";

const scrollToRef = (ref) => window.scrollTo(0, 0);

const Playlist = (props) => {

    const curriculumData = props?.data;
    const playlistProgress = props?.progress?.moduleWiseProgress;
    const totalAttempted = props?.progress?.totalAttempted;
    const totalProgress = props?.progress?.totalProgress;
    const totalMarksScored = props?.progress?.totalMarksScored;
    const certificate = props?.progress?.certificate;
    const courseSlug = props?.progress?.courseSlug;
    const topRef = useRef(null);
    const userName = useSelector(selectUserName);
    const history = useHistory();
    const executeScroll = () => scrollToRef(topRef);
    const { url } = useRouteMatch();

    return (
        <>
            <div
                className="flex flex-col w-full my-4 p-2"
                style={{ height: "400px", overflowY: "scroll" }}
            >
                {
                    curriculumData?.map((module, i) => {
                        const moduleProgress = playlistProgress.find(x => x.moduleNo == module.moduleNo);
                        return (
                            <div className="flex flex-col w-full m-0" key={i}>
                                <Link to={`?moduleNo=${module.moduleNo}`} onClick={executeScroll}>
                                    <div className="flex flex-row w-full items-center bg-white my-1 rounded-sm shadow-xl p-4 justify-between">
                                        <p className="text-md font-semibold">
                                            {i + 1}: {module.title}
                                        </p>
                                        {
                                            moduleProgress?.completed === true ?
                                                <div className="flex flex-row items-center gap-2">
                                                    <CheckCircle htmlColor="green" />
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </Link>

                            </div>
                        );
                    })
                }
                {
                    (totalProgress >= 98.5 && totalAttempted >= 60 && totalMarksScored >= 0) ?

                        <>
                            {
                                certificate === "" ?
                                    history.push(`/certificate/${courseSlug}`)
                                    :
                                    <div className="flex flex-col w-full m-0">
                                        <Link to={`/certificate/${courseSlug}`} onClick={executeScroll}>
                                            <div className="flex flex-row w-full items-center bg-white my-1 rounded-sm shadow-xl p-4 justify-between">
                                                <p className="text-md font-semibold">
                                                    Certificate
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                            }
                        </>

                        : null
                }
            </div>

        </>
    );
}

export default Playlist;