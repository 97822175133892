import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Grid, Typography, CircularProgress } from "@material-ui/core";
import { LinkedinShareButton, LinkedinIcon } from "react-share";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const CertificateCard = (props) => {

    const [loading, setLoading] = useState([]);
    const [certificateData, setCertificateData] = useState([]);
    const [isCredValid, setIsCredValid] = useState(false);

    useEffect(() => {
        getCertificate(props?.cred);
    }, []);


    const isNumeric = (num) => {
        return !isNaN(num)
    }

    const getCertificate = async (credID) => {
        setLoading(true);
        const credArray = credID.split('-');
        // credArray[0] = "RMK" -> constant,
        // credArray[1] = "smc" -> course name initials
        // credArray[2] = "9090" -> certificate count of the course + 1 
        // credArray[3] = "abcd" -> random string
        // credArray[4] = "aaa" -> string maintaining count above 9999 

        const courseSlugArray = props?.courseSlug.split('-');
        var courseInitials = "";
        for (let i = 0; i < courseSlugArray.length; i++) {
            courseInitials += courseSlugArray[i][0];
        }

        if (credArray[0] === "RMK" && credArray[1] === courseInitials && isNumeric(credArray[2]) && !isNumeric(credArray[3]) && credArray[3].length === 4 && !isNumeric(credArray[4]) && credArray[4].length === 3) {
            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/certificate/validate/credential/${credID}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });
                const validate = (await response.json());
                if (validate.apiStatus && validate.apiStatus != "SUCCESS" && validate.apiStatus != "Success" && validate.apiStatus != "success") {
                    return false;
                }
                setCertificateData(validate?.data);
                setIsCredValid(true);
                setLoading(false);
            } catch (error) {
                console.log("Error in Validating Credential ID", error);
            }
        } else {
            console.log("Invalid Cred ID");
        }
    }

    return (
        <>

            {
                certificateData?.certificateUrl ?
                    <Helmet>
                        <meta charset="utf-8" />
                        <title>Elearning - Recruiting Monk</title>
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <meta name="theme-color" content="#000000" />
                        <meta name="description" content="Elearning Platform for Recruiters by Recruiting Monk" />
                        <meta name="title" content="Elearning By Recruiting Monk" />
                        <meta name="medium" content="mult" />
                        <meta property="og:title" content="RecruitingMonk Course Completion Certificate" />
                        <meta property="og:image" content={certificateData?.certificateUrl} />
                        <meta property="og:type" content="video_lecture" />
                        <meta property="og:site_name" content="Elearning By Recruiting Monk" />
                        <meta property="og:locale" content="en_US" />
                        <meta itemprop="name" content="RecruitingMonk Course Completion Certificate" />
                        <meta itemprop="image" content={certificateData?.certificateUrl} />
                    </Helmet> : ''
            }
            {
                loading ?
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
                    :
                    <>

                        <Grid item xs={12} sm={6} md={4} lg={3} className="flex justify-center">
                            <Card>
                                <Link to={`/certificate/${props?.courseSlug}?credID=${props?.cred}`}>
                                    <CardMedia
                                        component="img"
                                        style={{ width: "280px", height: "360px", borderRadius: "10px" }}
                                        image={certificateData?.certificateUrl}
                                        alt={certificateData?.name}
                                    />
                                </Link>
                                <CardContent style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                                    <Typography gutterBottom style={{ color: "#ad2831", fontWeight: "bold" }} component="div">
                                        {certificateData?.title}
                                    </Typography>
                                    <Typography variant="body2" style={{ fontWeight: "bold" }} color="text.secondary">
                                        <Link to={`/certificate/${props?.courseSlug}?credID=${props?.cred}`}>
                                            Credential ID : {certificateData?.credentialID}
                                        </Link>
                                    </Typography>
                                    <Grid item style={{ margin: "10px 0px" }}>
                                        <LinkedinShareButton url={`https://learning.recruitingmonk.com/certificate/${props?.courseSlug}?credID=${props?.cred}`}
                                        >
                                            <button
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex"
                                            >
                                                <LinkedinIcon
                                                    style={{
                                                        borderRadius: "50%",
                                                        height: "30px",
                                                        width: "30px"
                                                    }}
                                                /> &nbsp; Linkedin
                                            </button>
                                        </LinkedinShareButton>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
            }
        </>
    );
}

export default CertificateCard;