// Global Imports
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Other File Imports 
import {
    USERSLIST
} from "./Columns";

import {
    Button,
    CircularProgress,
    Grid,
    Typography
} from "@material-ui/core";
// import Table from "./Table";
import TableSearch from "./TableSearch";

const Users = () => {
    // Design related
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [perPage, setPerPage] = useState(1000);

    // Data Fetching
    const [users, setUsers] = useState([]);
    const [tableData, setTableData] = useState(users);
    const [searchAll, setSearchAll] = useState(true);

    const [tableColumns, setTableColumns] = useState([]);
    const history = useHistory();



    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/users/list?perPage=${perPage + 1}&pageNumber=${pageNumber}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const userData = (await response.json());
                setUsers(userData.data);
                setTableData(userData.data);
                setTableColumns(USERSLIST);
                setSearchAll(true);
                if (userData.data.length > perPage) {
                    setPageNumber(pageNumber + 1);
                    setPerPage(perPage);
                }
                setLoading(false);
            } catch (error) {
                console.log("Courses cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }


    const activeUsers = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/users/active/list?perPage=${perPage + 1}&pageNumber=${pageNumber}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const userData = (await response.json());
                setUsers(userData.data);
                setTableData(userData.data);
                setTableColumns(USERSLIST);
                setSearchAll(false);
                if (userData.data.length > perPage) {
                    setPageNumber(pageNumber + 1);
                    setPerPage(perPage);
                }
                setLoading(false);
            } catch (error) {
                console.log("Courses cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }


    return (
        <>
            <Grid container className='top'>
                <Grid item xs={6} sm={8} md={9} style={{ padding: '20px 20px 0px 20px' }}>
                    <Typography
                        align="center"
                        style={{
                            fontSize: "32px",
                            color: "#6495ED",
                            fontWeight: "bold",
                        }}
                    >
                        Users Management
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} style={{ padding: '20px 20px 0px 20px' }}>
                    <p>
                        <b>Total Entries: {tableData.length}</b>
                    </p>
                </Grid>
            </Grid>

            {
                loading ?
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
                    :
                    <Grid container>
                        <div className="flex justify-end w-full md:px-20 px-4">
                            {
                                searchAll ?
                                    <Button
                                        variant="contained"
                                        className="Freshers_page_Apply_for_free"
                                        style={{
                                            marginTop: "10px",
                                            marginLeft: "4px"
                                        }}
                                        onClick={activeUsers}
                                    >
                                        Get Only Active Users
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        className="Freshers_page_Apply_for_free"
                                        style={{
                                            marginTop: "10px",
                                            marginLeft: "4px"
                                        }}
                                        onClick={getUsers}
                                    >
                                        Get All Users
                                    </Button>
                            }
                        </div>
                        <Grid
                            item
                            xs={12}
                            style={{ overflowX: 'auto', padding: '10px' }}>
                            {
                                tableData.length != 0 && tableColumns.length != 0 ?
                                    <TableSearch tableColumns={tableColumns} tableData={tableData} />
                                    :
                                    null
                            }

                        </Grid>
                    </Grid>
            }

        </>
    );
}

export default Users;