const TestimonialData = [
    // Left Hand Side
    {
        link: "https://www.linkedin.com/feed/update/urn:li:activity:6655367871155269633?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A6655367871155269633%2C6655375549189447680%29",
        image: "/testimonials/left-1.png",
        height: "230px",
        width: "100%",
        alt: "Testimonial"
    },
    {
        link: "https://www.linkedin.com/feed/update/urn:li:activity:6655367871155269633/?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A6655367871155269633%2C6655385179890245632%29",
        image: "/testimonials/left-2.png",
        height: "217px",
        width: "100%",
        alt: "Testimonial"
    },
    {
        link: "https://www.linkedin.com/pulse/practice-achyut-menon-ak-/",
        image: "/testimonials/left-3.png",
        height: "648px",
        width: "100%",
        alt: "Testimonial"
    },
    {
        link: "https://www.linkedin.com/feed/update/urn:li:activity:6657150515127910400/?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A6657150515127910400%2C6657908675371503616%29",
        image: "/testimonials/left-4.png",
        height: "136px",
        width: "100%",
        alt: "Testimonial"
    },
    // Right Hand Side
    {
        link: "https://www.linkedin.com/pulse/advanced-sourcing-swathy-sanjeevi/",
        image: "/testimonials/right-1.png",
        height: "637px",
        width: "100%",
        alt: "Testimonial"
    },
    {
        link: "https://www.linkedin.com/posts/adisharma26_tech-recruiters-advance-sourcing-i-activity-6653622743663157248-8ylC/",
        image: "/testimonials/right-2.png",
        height: "406px",
        width: "100%",
        alt: "Testimonial"
    },
    {
        link: "https://www.linkedin.com/feed/update/urn:li:activity:6667427834430140416/?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A6667427834430140416%2C6667516609491554304%29",
        image: "/testimonials/right-3.png",
        height: "189px",
        width: "100%",
        alt: "Testimonial"
    }
];

export default TestimonialData;