import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
    useHistory,
    useRouteMatch
} from "react-router-dom";

// Other Files Import
import "./login.css";

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const { path} = useRouteMatch();

    // useEffect(() =>{
    //     checkAutoLogin(dispatch,history);
    // },[])


    // const checkAutoLogin = (dispatch, history) => {
    //     const tokenDetailsString = localStorage.getItem('admin');
    //     let tokenDetails = '';
    //     if (!tokenDetailsString) {
    //         dispatch(logout(history));
    //         return;
    //     }
    
    //     tokenDetails = JSON.parse(tokenDetailsString);
    //     let expireDate = new Date(tokenDetails.expireDate);
    //     let todaysDate = new Date();
    
    //     if (todaysDate > expireDate) {
    //         dispatch(logout(history));
    //         return;
    //     }
    //     // dispatch(loginConfirmedAction(tokenDetails));
    
    //     const timer = expireDate.getTime() - todaysDate.getTime();
    //     runLogoutTimer(dispatch, timer, history);
    // }


    // const logout = (history)=>{
    //     localStorage.removeItem('admin');
    //     history.push('/home');
    // }

    const saveTokenInLocalStorage = (tokenDetails) => {
        // tokenDetails.expireDate = new Date(
        //     new Date().getTime() + 60 * 60 * 1000,
        // );
        localStorage.setItem('admin', JSON.stringify(tokenDetails))
    }

    // const runLogoutTimer = (dispatch, timer, history) => {
    //     setTimeout(() => {
    //         dispatch(logout(history));
    //     }, timer);  
    // }


    const formSubmit = async (e) => {
        e.preventDefault();
        let errors = "";
        // Form Validation 
        if (username === "") {
            errors += "\n🔹 Username is required cannot be left empty";
        }

        if (password === "") {
            errors += "\n🔹 Password is required cannot be left empty";
        }


        if (errors !== "") {
            alert("Your Form has the following Problems \n" + errors + "");
        } else {
            // Try Logging IN -------------------------------------------

            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/auth/login/admin`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        username: username,
                        password: password
                    })
                });
                const checking = (await response.json());
                if (checking.data !== undefined && checking.data.length!==0) {
                    saveTokenInLocalStorage(checking.data.jwtToken)
                    // runLogoutTimer(
                    //     dispatch,
                    //     60 * 60 * 1000,
                    //     history,
                    // );
                    setLoggedIn(true);
                } else if (checking.data.length === 0) {
                    setLoggedIn(false);
                    alert("\n🔹 INCORRECT LOGIN DETAILS");
                } else {
                    setLoggedIn(false);
                    alert("\n🔹"+checking.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }


    return (
        <>
            {
                loggedIn ?
                    <>  
                        {
                            history.push(`${path}/courses`)
                        }
                    </>
                    :
                    <div className="login-div-body">
                        <div className="login">
                            <h1>Login</h1>
                            <form onSubmit={formSubmit} >
                                <input type="text" name="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                <input type="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <button type="submit" className="btn btn-primary btn-block btn-large">Let me in.</button>
                            </form>
                        </div>
                    </div>
            }
        </>
    );
}

export default Login;