// Global Imports 
import React, { useState, useEffect } from "react";

// Desgin Imports
import { Grid, Typography } from "@material-ui/core";

const Author = (props) => {
    const [author, setAuthor] = useState({});


    useEffect(() => {
        getAuthorData(props.authorID);
    }, [props.authorID]);

    const getAuthorData = async (authorID) => {
        if (authorID) {
            try {

                const response = await fetch(`https://elearning-monks.azurewebsites.net/author/${authorID}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });
                const authorData = await response.json();
                if (authorData.apiStatus && authorData.apiStatus != "SUCCESS" && authorData.apiStatus != "Success" && authorData.apiStatus != "success") {
                    setAuthor(undefined);
                } else {
                    setAuthor(authorData.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    var AuthorData;

    if (props.authorID) {
        AuthorData = {
            heading: "About Trainer",
            name: author?.name !== undefined ? author?.name : "Ashfaq Ahmed",
            brief: author?.brief !== undefined ? author?.brief : "Founder & Head Monk @ Recruiting Monk",
            about: author?.about !== undefined ? author?.about : "Ashfaq is an Engineer by Chance, but a Recruiter by choice. He comes with about 8+ years of experience in Recruitment, out of which 7yrs have been in training & nurturing Recruiters. He has trained close to 10,000 Tech Recruiters. He is the founder of Recruiting Monk and in his current role he wears multiple hats like Product Manager, Trainer, Consultant etc..",
            image: author?.imageUrl !== undefined ? author?.imageUrl : "https://firebasestorage.googleapis.com/v0/b/rendition-prod.appspot.com/o/6e6ab994-354a-4ed9-b611-8887ba68217a.png?alt=media&token=675a8cc3-30ec-43b0-9492-99e45012785a"
        }
    } else {
        AuthorData = {
            heading: "Meet The Monk",
            name: "Ashfaq Ahmed",
            brief: "Founder & Head Monk @ Recruiting Monk",
            about: "Ashfaq is an Engineer by Chance, but a Recruiter by choice. He comes with about 8+ years of experience in Recruitment, out of which 7yrs have been in training & nurturing Recruiters. He has trained close to 10,000 Tech Recruiters. He is the founder of Recruiting Monk and in his current role he wears multiple hats like Product Manager, Trainer, Consultant etc..",
            image: "https://firebasestorage.googleapis.com/v0/b/rendition-prod.appspot.com/o/6e6ab994-354a-4ed9-b611-8887ba68217a.png?alt=media&token=675a8cc3-30ec-43b0-9492-99e45012785a"
        }
    }

    return (
        <>
            <Grid container
                style={{ padding: "0px 40px", width: "100%", margin: "0px" }}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        style={{
                            fontWeight: "bold",
                            display: "flex",
                            padding: "40px 0px 20px 0px",
                            justifyContent: "center",
                            color: "#ad2831"
                        }}
                    >
                        {AuthorData.heading}
                    </Typography>
                </Grid>
                <Grid item sm={12} md={6} style={{ justifyContent: "center", display: "flex" }}>
                    <img
                        src={AuthorData.image}
                        alt={AuthorData.brief}
                    />
                </Grid>
                <Grid item sm={12} md={6}>
                    <Typography
                        variant="h4"
                        style={{
                            fontWeight: "bold",
                            color: "#ad2831",
                        }}
                    >
                        {AuthorData.name}
                    </Typography>

                    <Typography
                        style={{
                            fontWeight: "bold",
                            fontSize: "21px",
                        }}
                    >
                        {AuthorData.brief}
                    </Typography>
                    <Typography
                        style={{ lineHeight: "35px", paddingTop: "30px", fontSize: "19px" }}
                    >
                        {AuthorData.about}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default Author;