import React, { useEffect } from "react";
import "./appinfo.css";
import Header from "../../component/global/Header";
import Footer from "../../component/global/Footer";
import { firebaseAnalytics } from "../../firebase";

const About = () => {
    useEffect(() => {
        document.title = "Contact | Elearning - Recruiting Monk";
        firebaseAnalytics.logEvent("contact_visited");
    });
    return (
        <>
            <Header />
            <div className="appinfo-body">
                <div className="appinfo-main shadow-inner">
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "13.999999999999998pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Contact Us
                        </span>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "13.999999999999998pt",
                                fontFamily: "Roboto,sans-serif",
                                color: "#000000",
                                backgroundColor: "#ffffff",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Our Office Address&nbsp;
                        </span>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Roboto,sans-serif",
                                color: "#000000",
                                backgroundColor: "#ffffff",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            :
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Roboto,sans-serif",
                                color: "#000000",
                                backgroundColor: "#ffffff",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            #1, 5th Cross, 17th &nbsp;Main road, <br />Aicobonagar, near udupi garden park,
                            <br />BTM Stage 1, <br />Bangalore, <br />560068
                        </span>
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default About;