import React from "react";
import styles from "../mini_diploma_component/HeaderCourse.module.css";

const Header = () => {
  return (
    <div className="relative w-full min-h-screen overflow-hidden">
      {/* Rotated Background Image */}
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          backgroundImage: `url('/genai/header.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          transform: "rotate(180deg)",
        }}
      ></div>

      {/* Content on top of the background */}
      <div className="relative z-10 flex flex-col items-center justify-start h-full px-2 md:px-0">
        <div className={styles.navbarContainer}>
          <img
            src="./images/logo.png"
            alt="Company Logo"
            style={{ width: "150px" }}
          />
          <div className={styles.navbarContents}>
            <div
              className={styles.navbaroption}
              onClick={() => {
                window.location.href = "https://learning.recruitingmonk.com/";
              }}
            >
              Home
            </div>
            <div
              className={styles.navbaroption}
              style={{ borderBottom: "1.5px solid #191919" }}
            >
              TA Gen AI
            </div>
          </div>
        </div>

        <h1 className="mt-20 md:mt-28 w-full md:w-2/3 text-[2.5rem] md:text-[5rem] lg:text-8xl font-bold text-center opacity-90">
          Master GEN AI in Talent Acquisition
        </h1>

        <div
          className="h-1/2 bg-white rounded-3xl w-full md:w-4/5 mt-20 px-2 md:px-12 py-6"
          style={{
            border: "2px solid rgba(0, 0, 0, 0.03)",
          }}
        >
          <div className="w-full text-left flex flex-col items-start gap-6 justify-start">
            <span className="flex">
              <span className="text-5xl pr-2 font-bold color text-gray-400">
                "
              </span>
              <h3>
                Reasons why you should{" "}
                <span style={{ color: "#f99c38" }}>
                  <br />not attend 
                  this course
                </span>{" "}
                <span className="text-gray-400 p-0 m-0">"</span>
              </h3>
            </span>

            <ul className="list-disc pl-12 text-xl text-gray-500">
              <li>Your experience is less than 3 years in TA</li>
              <li>You are looking for magic wand with immediate results</li>
              <li>You are not a curious learner</li>
              <li>Long duration course isn't your thingy</li>
              <li>You are here just for a CERTIFICATE</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
