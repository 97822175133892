import React from "react";
import ReactPlayer from "react-player";
import Typography from "@material-ui/core/Typography";

const VideosCard = (props) => {
    return (
        <>
            <div className="rounded-lg shadow-lg w-full flex flex-row flex-wrap p-3 antialiased">
                <div className="md:w-4/6 h-auto w-full">
                    <ReactPlayer
                        controls
                        className="video-player"
                        url={props?.data?.videoUrl}
                        title={props?.data?.title}
                    />
                </div>
                <div className="md:w-2/6 w-full px-3 flex flex-row flex-wrap">
                    <div className="w-full text-right text-gray-700 font-semibold relative pt-3 md:pt-0">
                        <div className="text-2xl text-gray-800 leading-tight">{props?.data?.heading}</div>
                        <div className="text-normal text-gray-700 hover:text-gray-400 cursor-pointer">
                            <span className="border-b border-dashed border-gray-700 pb-1">{props?.data?.title}</span>
                        </div>
                        <div className="text-normal text-gray-600 py-2 cursor-pointer">
                            {props?.data?.description}
                        </div>
                        <div className="text-sm text-gray-500 hover:text-gray-600 cursor-pointer md:absolute pt-3 md:pt-0 bottom-0 right-0">
                            <a href={props?.data?.userInfoUrl} target="_blank" className="py-2 grid grid-cols-4 gap-2">
                                <div className="h-12 w-12 bg-cover rounded-full col-span-1 text-center overflow-hidden" style={{ backgroundImage: `url("${props?.data?.userImageUrl}")` }} title={props?.data?.title}>
                                </div>
                                <div className="col-span-3 flex items-center">
                                    <Typography
                                        style={{
                                            fontWeight: "bold",
                                            color: "#ad2830",
                                            fontSize: "20px",
                                            fontFamily: "Roboto",
                                            lineHeight: "25px"
                                        }}
                                        variant="h6"
                                        component="h2"
                                    >
                                        {props?.data?.userName}
                                    </Typography>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VideosCard;