import React from "react";

const LandingAbout = () => {
  return (
    <section className="section_learn wf-section">
      <div className="padding-global padding-section-large">
        <div className="container-medium">
          <div className="learn_wrapper">
            <div className="learn_card">
              <img
                src="./images/about1.jpg"
                loading="lazy"
                width="510"
                sizes="(max-width: 479px) 84vw, (max-width: 767px) 400px, (max-width: 991px) 52vw, 510px"
                alt="true"
                className="image-2"
              />
              <div className="learn_content-wrapper">
                <h2>Craft the Art!</h2>
                <p className="learn_text">
                  Stories are not about cooking up random stuff. Story Telling
                  is an art that needs to be crafted with the right blend of
                  trust, empathy and data.
                </p>
              </div>
            </div>
            <div className="learn_card">
              <div className="learn_content-wrapper">
                <h2>Why you should Join?</h2>
                <p className="learn_text">
                  You're speaking out loud to your candidates and HMs, but no
                  one's listening to you. Then, this workshop is for you. Learn
                  the art of persuasion via storytelling
                </p>
              </div>
              <img
                src="./images/about2.jpg"
                loading="lazy"
                width="510"
                sizes="(max-width: 479px) 84vw, (max-width: 767px) 400px, (max-width: 991px) 52vw, 510px"
                alt="true"
                className="image-2"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingAbout;
