import React, { useState, useEffect } from "react";
import { Grid , CircularProgress } from "@material-ui/core";

const CourseCardMini = (props) => {
    const [loading, setLoading] = useState([]);
    const [courseData, setCourseData] = useState([]);

    useEffect(() => {
        getCourseData();
    }, []);

    const getCourseData = async () => {
        try {
            setLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/${props?.courseID}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const data = (await response.json());
            setCourseData(data.data);
            setLoading(false);
        } catch (error) {
            console.log("Featured Courses cannot be fetched.", error);
        }
    }


    return (
        <>
            {
                loading ?
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
                    :
                    <Grid item xs={12} sm={6} md={4} lg={3} className="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{courseData?.title}</h5>
                    </Grid>
            }

        </>
    );
}

export default CourseCardMini;