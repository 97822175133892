// Global Imports 
import React from "react";

// Other Components Imports

// Design Imports
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Pagination from "./Pagination";


const CoursesList = () => {
    return (
        <>
            <Grid container className="mt-6 px-4 md:px-20">
                <Grid item md={9} xs={12}>
                    <div
                        className="text-3xl font-semibold"
                        style={{ color: "#ad2831", fontWeight: "bold", marginTop : "12px" , marginBottom : "48px" }}
                    >
                        List of All Courses
                    </div>


                    {/* Course Data */}
                    <Pagination />

                    <div className="flex gap-3 items-center py-5">
                        <p>
                            Not able to find the course in which you are interested

                        </p>
                        <Button
                            onClick={() =>
                                window.open(
                                    "https://docs.google.com/forms/d/e/1FAIpQLScoZJTibo5ik6F-w9m-xZGFEG1QAlP-6Ysb5V77-ehWrB37bQ/viewform",
                                    "_blank"
                                )
                            }
                            variant="contained"
                            className="EM-btn"
                            id="CONTACT"
                        >
                            Contact Us
                        </Button>
                    </div>

                </Grid>
            </Grid>
        </>
    );
}

export default CoursesList;