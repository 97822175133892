import { Avatar, Grid, Typography } from "@material-ui/core";
import React from "react";
import Phone from "./Phone";

const UserInfo = (props) => {
    const data = {
        courseTaken:["Hello","World"],
        uid:props?.uid
    };
    return (
        <>
            <Grid container className="flex justify-between items-center">
                <Grid item xs={12} sm={12} md={4} lg={4} className="flex justify-center items-center p-2">
                    <Avatar src={props?.profile} className="rounded-full h-10 w-10 mx-1" />
                    <Typography
                        align="center"
                        style={{
                            fontSize: "32px",
                            color: "#ad2831",
                            fontWeight: "bold"
                        }}
                    >
                        {props?.name}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="p-2">
                    <Typography
                        align="center"
                        style={{
                            fontSize: "32px",
                            color: "#ad2831",
                            fontWeight: "bold"
                        }}
                    >
                        {props?.email}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="p-2">
                    <Typography
                        align="center"
                        style={{
                            fontSize: "32px",
                            color: "#ad2831",
                            fontWeight: "bold"
                        }}
                    >
                        <Phone data={data} />
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default UserInfo;