import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Header from "../../component/admin/Header";
import Users from "../../component/admin/Users";

const User = () => {
    const history = useHistory();

    useEffect(() => {
        loginCheck();
    }, [])

    const loginCheck = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/auth/check`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const status = await response.json();
                if (status.apiStatus !== "SUCCESS") {
                    history.push(`/home`);
                } else {
                    console.log("Successful Admin Auth");
                }
            } catch (error) {
                console.log("Error in Login Check", error);
                history.push(`/home`);
            }
        } else {
            history.push(`/home`);
        }
    }

    return (
        <>
    
            <Header />
            <Users />
        </>
    )
}

export default User;