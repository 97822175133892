// Global Imports
import React from "react";

const PageTitle = (props) => {
    return (
        <>
            <div className="w-full self-start flex flex-col md:flex-row md:items-center gap-2 mb-6 p-5 py-8 justify-between">
                <div
                    className="text-3xl font-bold"
                    style={{ color: "#ad2831" }}
                >
                    {props.title} Students Videos
                </div>
            </div>
        </>

    );
}

export default PageTitle;