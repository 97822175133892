// Global Imports 
import React from "react"
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux"

// Other File Imports
import { auth } from "../../firebase";
import {
    selectUserName,
    selectUserPhoto
} from "../../Providers/userSlice";

// Design Imports 
import { Button, TextField, Divider } from "@material-ui/core";
import Swal from "sweetalert2";
import { Grid, Hidden } from "@material-ui/core";
import Doubt from "./Doubt";

const Interaction = (props) => {
    const [tab, setTab] = useState("doubts");
    const [notes, setNotes] = useState("");
    const [doubts, setDoubts] = useState([]);
    const [authUserDoubt, setAuthUserDoubt] = useState("");
    const [added, setAdded] = useState(false);
    const [addedDoubt, setAddedDoubt] = useState({});
    const userName = useSelector(selectUserName);
    const userPhoto = useSelector(selectUserPhoto);
    const history = useHistory();

    useEffect(() => {
        if (props.notes !== "" && props.notes !== undefined && props.notes !== null)
            setNotes(props.notes.noteTxt);
        setDoubts(props.doubts);
    }, [props.doubts, props.notes]);

    const sendNote = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            try {
                                const response = await fetch(`https://elearning-monks.azurewebsites.net/users/notes/${props.courseSlug}?moduleNo=${props.moduleNo}`, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken,
                                        noteTxt: notes
                                    })
                                });
                                const selectedNoteData = await response.json();
                                if (selectedNoteData.apiStatus && selectedNoteData.apiStatus != "SUCCESS" && selectedNoteData.apiStatus != "Success" && selectedNoteData.apiStatus != "success") {
                                    history.push('/courses')
                                }
                                Swal.fire({
                                    icon: "success",
                                    title: "Successfully Added Notes.",
                                });
                            } catch (error) {
                                console.log(error)
                            }
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }
    }

    const updateNote = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            try {
                                const response = await fetch(`https://elearning-monks.azurewebsites.net/users/notes/${props.courseSlug}?moduleNo=${props.moduleNo}`, {
                                    method: "PATCH",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken,
                                        noteTxt: notes
                                    })
                                });
                                const selectedNoteData = await response.json();
                                if (selectedNoteData.apiStatus && selectedNoteData.apiStatus != "SUCCESS" && selectedNoteData.apiStatus != "Success" && selectedNoteData.apiStatus != "success") {
                                    history.push('/courses')
                                }
                                Swal.fire({
                                    icon: "success",
                                    title: "Successfully Updated Notes.",
                                });
                            } catch (error) {
                                console.log(error)
                            }
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }
    }

    const raiseDoubt = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            try {
                                const response = await fetch(`https://elearning-monks.azurewebsites.net/users/doubts/${props.courseSlug}?moduleNo=${props.moduleNo}`, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken,
                                        doubtTxt: authUserDoubt
                                    })
                                });
                                const selectedDoubtData = await response.json();
                                if (selectedDoubtData.apiStatus && selectedDoubtData.apiStatus != "SUCCESS" && selectedDoubtData.apiStatus != "Success" && selectedDoubtData.apiStatus != "success") {
                                    history.push('/courses')
                                }
                                Swal.fire({
                                    title: "Your Doubt is Posted!",
                                    icon: "success"
                                });
                                setAddedDoubt({
                                    _id: "",
                                    name: userName,
                                    profilePhoto: userPhoto,
                                    doubtTxt: authUserDoubt,
                                    answers: []
                                });
                                setAdded(true);
                                setAuthUserDoubt("");
                            } catch (error) {
                                console.log(error)
                            }
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }
    }



    return (
        <>

            <div className="flex flex-row items-center gap-10 font-semibold text-xl">
                <p
                    className={`${tab == "doubts" ? "border-b-4 border-gray-400" : ""
                        } cursor-pointer`}
                    onClick={() => setTab("doubts")}
                >
                    Ask a Doubt
                </p>
                <p
                    className={`${tab == "notes" ? "border-b-4 border-gray-400" : ""
                        } cursor-pointer`}
                    onClick={() => setTab("notes")}
                >
                    Add your notes
                </p>
            </div>
            <div
                className={`${tab == "doubts" ? "block" : "hidden"
                    } mt-6 w-full border-1 border-gray-400 p-2 min-h-96 bg-gray-100`}
            >
                {/* Doubts Data  */}
                <Grid container>
                    <Grid item xs={12} sm={9} md={9}>
                        <div
                            className="Question-container"
                        >
                            <TextField
                                fullWidth
                                multiline
                                value={authUserDoubt}
                                onChange={(e) => setAuthUserDoubt(e.target.value)}
                                maxRows={5}
                                className="Question-field"
                                minRows={2}
                                label="Ask Question"
                                placeholder="Type Your Question Here"
                                variant="outlined"
                            />
                        </div>
                    </Grid>
                    <Hidden only={["lg", "md", "sm", "xl"]}>
                        <Grid item xs={9}></Grid>
                    </Hidden>
                    <Grid item xs={3} sm={3} md={3}>
                        <div
                            style={{ paddingTop: "20px" }}
                            className="Question-container-buttons"
                        >
                            <Button
                                size="small"
                                variant="contained"
                                className="Submit-Question"
                                disabled={authUserDoubt.length === 0 ? true : false}
                                onClick={raiseDoubt}
                            >
                                Ask
                            </Button>
                        </div>
                    </Grid>

                    <Grid container style={{ marginLeft: "15px" }}>
                        <Grid item xs={12} md={9} className="ques-box">
                            {
                                added ?
                                    <Doubt doubt={addedDoubt} />
                                    :
                                    null
                            }
                            {
                                doubts?.map((doubt, i) => {
                                    return (
                                        <Doubt key={i} doubt={doubt} />
                                    )
                                })
                            }
                        </Grid>
                    </Grid>


                </Grid>
            </div>
            <div
                style={{ overflow: "scroll", overflowX: "hidden" }}
                className={`${tab == "notes" ? "flex" : "hidden"
                    } mt-6 w-full border-1 flex-col gap-4 border-gray-400 p-6 max-h-96 bg-gray-100  overflow`}
            >
                <div>
                    <TextField
                        fullWidth
                        label="Your Notes"
                        variant="outlined"
                        value={notes}
                        multiline
                        onChange={(e) => setNotes(e.target.value)}
                        minRows={6}
                    />
                    {
                        props.notes === null ?
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                onClick={sendNote}
                            >
                                Add Note
                            </Button>
                            :
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                onClick={updateNote}
                            >
                                Update Note
                            </Button>
                    }

                </div>
            </div>
        </>
    );
}

export default Interaction;