// Global File Imports
import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

// Other File Imports 
import { auth } from "../../firebase";
import Login from "./Login";

// Design Imports 
import { CircularProgress } from "@material-ui/core";

const Admin = () => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            adminCheck();
        });
    }, []);

    const loginCheck = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/auth/check`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const status = await response.json();
                if (status.apiStatus === "SUCCESS") {
                    history.push(`${path}/courses`);
                } else {
                    console.log(status);
                }
            } catch (error) {
                console.log("Error in Login Check", error);
            }
        }
    }

    const adminCheck = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {

                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {

                            console.log(idToken)

                            try {

                                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/check`, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken,
                                    })
                                });
                                const adminCheckResponse = (await response.json());
                                if (adminCheckResponse.data === "ADMIN") {
                                    loginCheck();
                                    setVerified(true);
                                } else {
                                    history.push("/home");
                                }
                            } catch (error) {
                                console.log(error)
                                history.push("/home");
                            }
                        }).catch(function (error) {
                            console.log(error)
                            history.push("/home");
                        });
                    } else {
                        history.push("/home");
                    }
                } else {
                    history.push("/home");
                }
            });
        } catch (error) {
            console.log("Error in Admin Check", error);
        }
    }

    return (
        <>
            {
                verified ?
                    <>
                        <Login />
                    </>
                    :
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
            }
        </>
    );
}

export default Admin;