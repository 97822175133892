import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Header from "../../component/global/Header";
import Footer from "../../component/global/Footer";
import VideosSection from "../../component/videos/VideosSection";
import PageTitle from "../../component/videos/PageTitle";



const StudentVideos = (props) => {
    const [courseData, setCourseData] = useState([]);
    const [courseDataLoading, setCourseDataLoading] = useState(true);
    const history = useHistory();
    const { courseSlug } = useParams();

    useEffect(() => {
        getCourseData();
    }, []);

    const getCourseData = async () => {
        try {
            setCourseDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/${courseSlug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const selectedCourseData = (await response.json());
            if (selectedCourseData.apiStatus && selectedCourseData.apiStatus != "SUCCESS" && selectedCourseData.apiStatus != "Success" && selectedCourseData.apiStatus != "success") {
                history.push('/courses');
            }
            setCourseData(selectedCourseData.data);
            setCourseDataLoading(false);
        } catch (error) {
            console.log("Courses cannot be fetched.", error);
        }
    }

    return (
        <>
            <Header />
            {courseDataLoading ?
                <div className="w-screen h-screen grid place-items-center">
                    <CircularProgress />
                </div>
                : 
                <PageTitle title={courseData?.title} /> 
            }

            <VideosSection courseSlug={courseSlug} />

            <Footer />
        </>
    );
}

export default StudentVideos;


