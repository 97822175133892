import { Grid } from "@material-ui/core";
import React from "react";

const UserInfoLoading = () => {
    return (
        <>
            <Grid item xs={12} md={8} className="flex justify-end gap-2 items-center my-auto">
                <h1 className="w-40 h-10 animate-pulse bg-red-300"></h1>
                <div className="h-10 w-10 rounded-full bg-red-200"></div>
            </Grid>
        </>
    );
}

export default UserInfoLoading;