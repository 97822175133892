import React, { useEffect, useState } from "react";

import CertificateCard from "./CertificateCard";

import { Grid, Typography } from "@material-ui/core";

const Certificate = (props) => {


    return (
        <>

            <Grid container>
                <Typography
                    align="center"
                    style={{
                        fontSize: "32px",
                        color: "#6495ED",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        padding: "10px"
                    }}
                >
                    Certificates
                </Typography>
            </Grid>
            <Grid container>
                {
                    props?.courses?.map((course, i) => {
                        return (
                            <>
                                {
                                    course?.certificate !== "" ?
                                        <CertificateCard cred={course?.certificate} courseSlug={course?.courseSlug} key={i} />
                                        :
                                        null
                                }
                            </>
                        )
                    })
                }
            </Grid>
        </>
    );
}

export default Certificate;