// Global Imports
import React from "react";

// Design Imports 
import { Grid, Typography } from "@material-ui/core";
import TestimonialCard from "./TestimonialCard";


const Testimonials = (props) => {
    return (
        <>
            <Typography
                variant="h4"
                gutterBottom
                style={{ color: "#ad2831", fontWeight: "bold" }}
            >
                Student Testimonials
            </Typography>
            <Grid container spacing={4} className="flex justify-center items-stretch">
                {
                    props.testimonials?.map((data, i) => {
                        return (
                            <Grid  key={i} item xs={12} sm={6} md={4}>
                                <TestimonialCard data={data} className="h-full" />
                            </Grid>
                        );
                    })
                }
            </Grid>
        </>

    );
}

export default Testimonials;