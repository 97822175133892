import axios from "axios";
import React from "react";

const LandingHeader = () => {

  // window.location.href = "/join_group"

  const initPayment = (data) => {
    const options = {
      key: "rzp_test_hlt7enZgENqXG4",
      amount: data.amount,
      currency: data.currency,
      name: "Impact Lives for Meaningful Stories",
      description: "Test Transaction",
      // image: book.img,
      order_id: data.id,
      handler: async (response) => {
        try {
          const verifyUrl = "http://localhost:8080/api/payment/verify";
          const { data } = await axios.post(verifyUrl, response);
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#f99b37",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const payement = async () => {
    try {
      const orderUrl = "http://localhost:8080/api/payment/orders";
      const { data } = await axios.post(orderUrl, { amount: 1599 });
      console.log(data);
      initPayment(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="section_hero wf-section">
      <div className="padding-global padding-section-large">
        <div className="container-large">
          <div className="hero_wrapper">
            <div className="text-block-3">Story Telling</div>
            <div className="hero_content-wrapper">
              <div className="h1_wrapper">
                <div>
                  <h1>
                    Impact Lives with
                    <p className="text-color-red">Meaningful Stories</p>
                  </h1>
                  <p className="line-height-180" style={{ marginTop: "20px" }}>
                    Impact the lives of your candidates and hold meaningful
                    conversations with your hiring managers. Join me as I teach
                    you how to craft stories that capture minds!
                  </p>
                </div>
                <img
                  src="./images/ashfaq_banner.jpg"
                  loading="lazy"
                  alt="true"
                  className="hero_image"
                />
              </div>
              <div className="hero_heighlight">
                <div className="heighlight_card1">
                  <div className="html-embed w-embed timedate">
                  <svg width="35" height="35" viewBox="0 -3 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.9884 9.5859V3.9743c1.9922.9374 3.5352 1.8429 5.36 2.8192-1.505.8347-3.3678 1.7712-5.36 2.7924m11.1026-8.403C18.7473.73 18.1616.378 17.538.2614c-1.8328-.348-13.267-.349-15.0989 0-.5.0938-.9453.3201-1.3278.6722C-.5004 2.4295.0047 10.4516.3931 11.751c.1634.5624.3746.9683.6405 1.2346.3427.352.8119.5944 1.3508.703 1.509.3122 9.2836.4867 15.1218.047a2.6195 2.6195 0 0 0 1.3896-.7111c1.4901-1.49 1.3885-9.9628.1952-11.8416" fill="#000" fillRule="evenodd"/></svg>
                  <p>3-4 Hours of Learning</p>
                  </div>
                  <div className="html-embed w-embed timedate">
                  <svg width="35" height="35" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.5 2h13l.5.5v5.503a5.006 5.006 0 0 0-1-.583V3H2v9h5a5 5 0 0 0 1 3H4v-1h3v-1H1.5l-.5-.5v-10l.5-.5z"/><path d="M12 8c.367 0 .721.047 1.063.14.34.094.658.23.953.407.294.177.563.385.808.625.245.24.455.509.63.808a4.03 4.03 0 0 1 .405 3.082c-.093.342-.229.66-.406.954a4.382 4.382 0 0 1-.625.808c-.24.245-.509.455-.808.63a4.029 4.029 0 0 1-3.082.405 3.784 3.784 0 0 1-.954-.406 4.382 4.382 0 0 1-.808-.625 3.808 3.808 0 0 1-.63-.808 4.027 4.027 0 0 1-.405-3.082c.093-.342.229-.66.406-.954.177-.294.385-.563.625-.808.24-.245.509-.455.808-.63A4.028 4.028 0 0 1 12 8zm2 3.988L11 10v4l3-2.012z"/></svg>
                    <p>Virtual Learning Program</p>
                  </div>
                </div>

                
                <div className="heighlight_card1">
                  <div className="html-embed w-embed timedate">
                  <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.75 2.5a.75.75 0 0 0-1.5 0v1.5793c-1.4393.1152-2.3842.398-3.0784 1.0923-.6942.6942-.977 1.639-1.0923 3.0784h19.8414c-.1152-1.4393-.3981-2.3842-1.0923-3.0784-.6942-.6942-1.6391-.977-3.0784-1.0923V2.5a.75.75 0 0 0-1.5 0v1.5129C15.5847 4 14.839 4 14 4h-4c-.839 0-1.5847 0-2.25.0129V2.5Z" fill="#000000"/><path fillRule="evenodd" clipRule="evenodd" d="M2 12c0-.839 0-1.5847.0129-2.25h19.9742C22 10.4153 22 11.161 22 12v2c0 3.7712 0 5.6569-1.1716 6.8284C19.6569 22 17.7712 22 14 22h-4c-3.7712 0-5.6569 0-6.8284-1.1716C2 19.6569 2 17.7712 2 14v-2Zm15 2c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1Zm0 4c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1Zm-4-5c0 .5523-.4477 1-1 1s-1-.4477-1-1 .4477-1 1-1 1 .4477 1 1Zm0 4c0 .5523-.4477 1-1 1s-1-.4477-1-1 .4477-1 1-1 1 .4477 1 1Zm-6-3c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1Zm0 4c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1Z" fill="#000000"/></svg>
                    <p>Date : 22nd & 23rd July</p>
                  </div>

                  <div className="html-embed w-embed timedate">
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 12c0 5.5228-4.4772 10-10 10-5.5229 0-10-4.4772-10-10C2 6.4771 6.4771 2 12 2c5.5228 0 10 4.4771 10 10Z"
                        fill="#000"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 7.25a.75.75 0 0 1 .75.75v3.6893l2.2803 2.2804a.75.75 0 0 1 0 1.0606.75.75 0 0 1-1.0606 0l-2.5-2.5A.7497.7497 0 0 1 11.25 12V8a.75.75 0 0 1 .75-.75Z"
                        fill="#fff"
                      />
                    </svg>
                    <p>Time : 7.00PM - 8.30PM</p>
                  </div>
                </div>
              </div>
              <button onClick={payement} className="button w-button" style={{cursor:"pointer"}}>
                Register today for ₹1,599.00
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingHeader;
