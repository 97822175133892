import React, { useEffect } from "react";
import {
    useHistory,
} from "react-router-dom";

// Other Files
import Header from "../../component/admin/Header";



const Dashboard = () => {
    const history = useHistory();

    useEffect(() => {
        loginCheck();
    }, [])

    const loginCheck = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/auth/check`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const status = await response.json();
                if (status.apiStatus !== "SUCCESS") {
                    history.push(`/home`);
                } else {
                    console.log("Successful Admin Auth");
                }
            } catch (error) {
                console.log("Error in Login Check", error);
                history.push(`/home`);
            }
        } else {
            history.push(`/home`);
        }
    }





    return (
        <>
            <Header />
        </>
    );
}

export default Dashboard;