import React from "react";
import CourseCard from "./CourseCard";
import { Grid, Typography } from "@material-ui/core";

const CourseTaken = (props) => {
    return (
        <>

            <Grid container>
                <Typography
                    align="center"
                    style={{
                        fontSize: "32px",
                        color: "#6495ED",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        padding: "10px"
                    }}
                >
                    Course Taken
                </Typography>
            </Grid>
            <Grid container>
                {
                    props?.courses?.map((course, i) => {
                        return (
                            <CourseCard course={course} key={i} />
                        )
                    })
                }
            </Grid>
        </>
    );
}

export default CourseTaken;