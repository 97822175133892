import React, { useState } from "react";
import styles from "./FAQ.module.css";
import { useEffect } from "react";

const FAQ = () => {
  const [open, setOpen] = useState(null);

  const questions = [
    {
      question: "Is it a live session?",
      asnwer: `It is a combination of live + Recorded. Every week you will have
                one live session with Ashfaq. You’d get videos/task/assignments
                to be completed everyweek at your own flexible time zones.`,
    },

    {
      question: "Will I get a certificate after the session?",
      asnwer: "Yes, after completing the session, you will get a certificate.",
    },

    {
      question: "Who can attend this?",
      asnwer: `Anyone with atleast one year of experience in Talent
                  Acquisition`,
    },
  ];

  const handleShowAnswer = (key) => {
    if (key !== open) {
      setOpen(key);
    } else {
      setOpen(null);
    }
  };

  return (
    <div className={styles.faq_wrapper}>
      <div className={styles.faq_subcontainer}>
        <div className={styles.faq_heading}>Frequently Asked Questions</div>
        <div className={styles.faq_content}>
          {questions.map((question, key) => (
            <div
              className={styles.question_container}
              onClick={() => handleShowAnswer(key)}
              key={key}
            >
              <div className={styles.question_side}>
                <div className={styles.faq_question}>{question.question}</div>
                <div className={`${styles.faq_answer} ${open === key ? `${styles.show}` : `${styles.hide}`}`}>
                  {question.asnwer}
                </div>
              </div>
              <div className={styles.dropdown}>
                <img
                  src="/images/dropdown.svg"
                  alt=""
                  style={{ width: "15px" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
