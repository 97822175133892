import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth } from '../../firebase';

import { Button, CircularProgress, Grid } from '@material-ui/core';

const DoubtText = (props) => {
    const [loading, setLoading] = useState(true);
    const [doubtData, setDoubtData] = useState({});
    const history = useHistory();

    useEffect(() => {
        getDoubt();
    }, []);

    const getDoubt = async () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            setLoading(true);
                            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/doubts/get/id/${props?.doubtID}`, {
                                method: "POST",
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({  
                                    token: idToken,
                                })
                            });
                            const selectedDoubtData = (await response.json());
                            if (selectedDoubtData.apiStatus != "Success" && selectedDoubtData.apiStatus != "SUCCESS" && selectedDoubtData.apiStatus != "success") {
                                console.log(selectedDoubtData);
                            }
                            setDoubtData(selectedDoubtData.data);
                            setLoading(false);

                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
    } catch (error) {
        console.log("User Doubts cannot be fetched.", error);
    }
}



return (
    <>
        {
            loading ?
                <div className="w-screen h-screen grid place-items-center">
                    <CircularProgress />
                </div>
                :
                <Grid container className="flex items-center p-1 border-2 bg-gray-100 hover:bg-gray-200 font-medium text-center">
                    <Grid item xs={12} sm={6} md={2} className="py-1">
                        {doubtData?.courseName}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="py-1">
                        {doubtData?.moduleTitle}
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} className="py-1">
                        {doubtData?.doubtTxt?.substr(0, 100)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className="py-1">
                        <Button variant="contained" color="primary" className="button-default">
                            <Link to={`/learn/${doubtData?.courseSlug}?moduleNo=${doubtData?.moduleNo}`} target="_blank">View</Link>
                        </Button>
                    </Grid>
                </Grid>
        }
    </>
);
}

export default DoubtText;